import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';
import ImageDocument from './DocumentRenderers/ImageDocument';
import {
  b64DecodeUnicode,
  base64ToArrayBuffer,
  generateDataURI,
  tiffToPng,
} from '../../dmpconnect/utils/dataUtils';
import {
  documentFormatNames,
  documentFormats, documentFormatsArray
} from '../../dmpconnect/constants';
import Platform from "react-platform-js";
import DOMPurify from 'dompurify';
import {detectFormat} from "../../dmpconnect/helpers/documentUtils";
import HRDocument from "../HR/HRDocument";
import CenteredCard from "../Common/Card/CenteredCard";
import Loading from "../Common/Loading/Loading";
import Alert from "../Common/Message/Alert";
import lazyWithRetry from "../../lib/lazyWithRetry";
import MobileHRDocument from "../HR/MobileHRDocument";

const RTFDocument = lazyWithRetry(() => import('./DocumentRenderers/RTFDocument'));
const PDFDocument = lazyWithRetry(() => import('./DocumentRenderers/PDFDocument'));
const PDFIframeDocument = lazyWithRetry(() => import('./DocumentRenderers/PDFIframeDocument'));

export const documentClassName = {
  [documentFormats.textPlain]: 'txt',
  [documentFormats.richTextFormat]: 'rtf',
  [documentFormats.jpeg]: 'jpeg',
  [documentFormats.tiff]: 'tiff',
  [documentFormats.pdf]: 'pdf',
}
const DMPDocumentContent  = ({
  content,
  preview,
  pager,
  cdaContent,
  information,
  vsmClassName,
  stylesheetApplied,
  showHeadersWhenStylesheetApplied,
    isMobile,
}) => {
  const [format, setFormat] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [errorFormat, setErrorFormat] = React.useState(false);
  // const [previewAvailable, setPreviewAvailable] = React.useState(true);

  React.useEffect(() => {
    if (content) {
      setLoading(true);
      detectFormat(information.i_document_Format, content).then(detectedFormat => {

        if (documentFormatsArray[information.i_document_Format] && detectedFormat !== information.i_document_Format) {
          setErrorFormat(true);
        }
        setLoading(false);
        setFormat(detectedFormat);
        // setPreviewAvailable(documentFormatPreviews[detectedFormat] || false);
      });
    }
  }, [information.i_document_Format, content]);

  const generateObject = (content, format) => {
    switch (format) {
      case documentFormats.jpeg:
        return <ImageDocument src={generateDataURI(content, format)} />;
      case documentFormats.tiff:
        return <ImageDocument src={tiffToPng(base64ToArrayBuffer(content))} />;
      case documentFormats.pdf:
        return (
          <Suspense fallback={(
            <div className="documentPage centered-container">
              <Spinner animation="border" />
              <span className="sr-only">Loading...</span>
            </div>
          )}>
            {preview === true
              ? <PDFDocument data={content.replace(/[\n\r]+/g, '')} pager={pager} />
              : <div className="pdf"><PDFIframeDocument data={content.replace(/[\n\r]+/g, '')} /></div>
            }
          </Suspense>
        );
      case documentFormats.richTextFormat:
        return (
          <Suspense fallback={(
            <div className="documentPage centered-container">
              <Spinner animation="border" />
              <span className="sr-only">Loading...</span>
            </div>
          )}>
            {!isMobile && !preview && (
            <Alert type="warning">
              <span>La visualisation de ce type de document n&apos;est pas optimale avec un navigateur. En cas de problème d&apos;affichage, veuillez le télécharger et l&apos;ouvrir sur votre poste.</span>
            </Alert>
            )}
            <RTFDocument data={content}  />
          </Suspense>
        );
      case documentFormats.textPlain:
        return <div className="documentPage txt">{b64DecodeUnicode(content)}</div>;
      case documentFormats.hr:
        return isMobile ? <MobileHRDocument cdaContent={b64DecodeUnicode(cdaContent)} /> : <HRDocument cdaContent={b64DecodeUnicode(cdaContent)} />;
      default:
        if (stylesheetApplied) {
          const htmlcontent = DOMPurify.sanitize( b64DecodeUnicode(content) , { USE_PROFILES: { html: true } } );
          const withoutHeaders = !showHeadersWhenStylesheetApplied ? '<style>.header_table, br { display: none !important; }</style>' : '';
          return (
            <div className={`flex-grow-1 overflow-hidden ${vsmClassName} stylesheet-applied`} style={{ height: '100%' }}>
              <iframe
                title="CDA Content"
                srcDoc={`${withoutHeaders}${htmlcontent}`}
                width="100%"
                height="100%"
              ></iframe>
            </div>
          );
        } else {
          return <div className={vsmClassName} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( b64DecodeUnicode(content) , { USE_PROFILES: { html: true } } ) }} />;
        }
    }
  };


    // const previewAvailable = documentFormatPreviews[format] || false;

    return (
      <>
        {loading ? (
          <CenteredCard>
            <Loading message="Chargement du document ..." />
          </CenteredCard>
        ) : (
          <>
            {errorFormat ? (
              <Alert type="danger">
                <strong className="d-block">Affichage désactivé.</strong>
                Le contenu détecté n'est pas au format indiqué (
                {documentFormatNames[information.i_document_Format]}
                ).
                <br />
                Vous pouvez toujours télécharger le document en cliquant sur le bouton en haut de page.
              </Alert>
            ) : (
              <>
            {format && format !== -1 && !(format === documentFormats.richTextFormat && Platform.Browser === 'IE') ? (
              content && generateObject(content, format)
            ) : (
              <div className="text-xl-center m-t-20 m-b-20"> Aperçu non disponible</div>
            )}
                </>
              )}
          </>
        )}
      </>
    );

}

DMPDocumentContent.propTypes = {
  content: PropTypes.string.isRequired,
  cdaContent: PropTypes.string,
  information: PropTypes.object.isRequired,
  preview: PropTypes.bool,
  pager: PropTypes.bool,
  stylesheetApplied: PropTypes.bool,
  showHeadersWhenStylesheetApplied: PropTypes.bool,
  vsmClassName: PropTypes.string,
  isMobile: PropTypes.bool,
};

DMPDocumentContent.defaultProps = {
  preview: false,
  pager: false,
  stylesheetApplied: false,
  showHeadersWhenStylesheetApplied: false,
  cdaContent: '',
  vsmClassName: 'vsm hide-meta',
  isMobile: false,
};

export default DMPDocumentContent;
