import { put, select, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  getLoginReferer,
} from 'dmpconnectjsapp-base/helpers/accessors';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { dmpCommandSuccessContextualizedType } from 'dmpconnectjsapp-base/actions';
import {
  findDocumentByUniqueId,
  getFindDocumentsLastSearchTimeStamp as getFindDocumentsLastSearchTimeStampAccessor,
  getFindDocumentsParams as getFindDocumentsParamsAccessor,
} from '../helpers/findDocuments';
import { clearLoginReferer, getAction } from '../actions';
import { getHomePagePath } from '../helpers/nav';


export const redirectAfterLogin = function* () {
  const loginReferer = yield select(getLoginReferer);

  if (loginReferer) {
    yield put(clearLoginReferer());
    yield put(push(loginReferer));
  } else {
    const homePagePath = yield select(getHomePagePath);
    yield put(push(homePagePath));
  }
};// const getAllNewDocsFromCache = (state, ins) => {
export const getFindDocumentsParams = state => getFindDocumentsParamsAccessor(state);
export const getFindDocumentsLastSearchTimeStamp = state => getFindDocumentsLastSearchTimeStampAccessor(
  state,
);
export const getDocumentInfoFromCache = (state, ins, uniqueId) => {
  const {
    dmpconnectDocumentsCache: {
      [ins]: {
        documents: {
          [uniqueId]: document,
        } = {},
      } = {},
    },
  } = state;
  return document;
};
export const getAllDocsFromCache = (state, ins) => {
  const {
    dmpconnectDocumentsCache: {
      [ins]: {
        documents = {},
      } = {},
    },
  } = state;
  return Object.values(documents);
};
//   const {
//     dmpconnectDocumentsCache: {
//         [ins]: {
//           documents,
//         } = {},
//     },
//   } = state;
//   return findNewDocuments(documents);
// };
export const getNewDocFromCache = (state, ins, uniqueId, exeptUuid) => {
  const {
    dmpconnectDocumentsCache: {
      [ins]: {
        documents,
      } = {},
    },
  } = state;

  const [doc] = findDocumentByUniqueId(documents, uniqueId, exeptUuid);
  return doc;
};

export const hardResetApplication = function* () {
  yield put(getAction(
    commands.clearPersistantData,
    apiSections.CLEAR_PERSISTANT_DATA,
    undefined,
    { contextExtra: { refresh: false } },
  ));

  yield take(dmpCommandSuccessContextualizedType(apiSections.CLEAR_PERSISTANT_DATA));

  localStorage.clear();
  indexedDB.deleteDatabase('efficience_documents');
  indexedDB.deleteDatabase('efficience_mss_messages');
  window.location.href = '/';
};
