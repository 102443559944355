import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Col, Form } from 'react-bootstrap';
import merge from 'lodash.merge';
import { sexes } from '../../../dmpconnect/constants/dmpConstants';
import ButtonWithLoader from '../../Common/Form/ButtonWithLoader';
import { findPatientsDefaultValues, findPatientsParamsSchema } from '../../../dmpconnect/helpers/searchUtils';
import InputDate from '../../Common/Form/Input/InputDate';
import CustomCheckbox from '../../Common/Form/Input/CustomCheckbox';
import Alert from '../../Common/Message/Alert';

const SearchForm = ({
  onSubmit, loading, initialValues, resetParamsAndResults, ...props
}) => {
  const formikRef = React.useRef();
  React.useEffect(() => {
    if (formikRef && formikRef.current) {
      formikRef.current.validateForm();
    }
  }, [formikRef]);

  const handleResetForm = (resetForm, validateForm) => {
    resetParamsAndResults();
    resetForm(findPatientsDefaultValues);
    validateForm();
  };

  const initialValuesMerged = merge({}, findPatientsDefaultValues, initialValues);
  return (
    <React.Fragment>
      <Formik
        isInitialValid={findPatientsParamsSchema.isValidSync(initialValuesMerged)}
        ref={formikRef}
        validationSchema={findPatientsParamsSchema}
        onSubmit={onSubmit}
        initialValues={initialValuesMerged}
      >
        {(
          {
            validateForm,
            resetForm,
            handleSubmit,
            handleChange,
            values,
            errors,
            isValid,
          },
        ) => (
          <Form {...props} noValidate onSubmit={handleSubmit}>
            {errors.enoughParams && (
              <Alert type="danger">
                Vous devez remplir au moins un des critères de recherche suivants :
                <ul className="mb-0">
                  <li>Nom</li>
                  <li>Prénom</li>
                  <li>Date de naissance</li>
                  <li>Ville</li>
                  <li>Code postal</li>
                  <li>Sexe</li>
                </ul>
              </Alert>
            )}
            <Form.Row>
              <Col sm={3} className="d-flex flex-row align-items-center">
                <Form.Control
                  size="sm"
                  name="name"
                  id="dmpSearchName"
                  label="Nom"
                  value={values.name || ''}
                  onChange={handleChange}
                  placeholder="Nom"
                  isInvalid={!!errors.name}
                />
                <CustomCheckbox
                  className="ml-3"
                  id="approxName"
                  name="approxName"
                  checked={values.approxName}
                  onChange={handleChange}
                  label="Approx."
                  inline
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Col>
              <Col sm={3}>
                <Form.Control
                  size="sm"
                  name="givenName"
                  id="dmpGivenName"
                  label="Prénom"
                  value={values.givenName || ''}
                  onChange={handleChange}
                  placeholder="Prénom"
                  isInvalid={!!errors.givenName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.givenName}
                </Form.Control.Feedback>
              </Col>
              <Col sm={3}>
                <InputDate
                  size="sm"
                  name="birthday"
                  id="dmpSearchBirthday"
                  isValid={!errors.birthday}
                  onChange={handleChange}
                  value={values.birthday || ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.birthday}
                </Form.Control.Feedback>
              </Col>
              <Col sm={3}>
                <Form.Control
                  size="sm"
                  as="select"
                  value={values.sex}
                  onChange={handleChange}
                  name="sex"
                  placeholder="Sexe"
                  id="dmpSearchSex"
                  isInvalid={!!errors.sex}
                >
                  <option value={0} disabled hidden>Sexe</option>
                  {sexes.map(sex => (
                    <option
                      key={sex.value}
                      value={sex.value}
                    >
                      {sex.label}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Row>
            <Form.Row className="mt-2">
              <Col sm={3} className="d-flex flex-row align-items-center">
                <Form.Control
                  size="sm"
                  name="city"
                  id="dmpSearchCity"
                  label="Ville"
                  placeholder="Ville"
                  value={values.city || ''}
                  onChange={handleChange}
                  isInvalid={!!errors.city}
                />
                <CustomCheckbox
                  className="ml-3"
                  id="approxCity"
                  name="approxCity"
                  checked={values.approxCity}
                  onChange={handleChange}
                  label="Approx."
                  inline
                />
                <Form.Control.Feedback type="invalid">
                  {errors.city}
                </Form.Control.Feedback>
              </Col>
              <Col sm={3}>
                <Form.Control
                  size="sm"
                  name="postalCode"
                  id="dmpSearchPostalCode"
                  label="Code postal"
                  placeholder="Code postal"
                  value={values.postalCode || ''}
                  onChange={handleChange}
                  isInvalid={!!errors.postalCode}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.postalCode}
                </Form.Control.Feedback>
              </Col>
              <Col className="d-flex justify-content-end align-items-end">
                <button
                  type="button"
                  className="btn btn-borderless-primary btn-sm mr-2"
                  onClick={() => handleResetForm(resetForm, validateForm)}
                >
                  Tout réinitialiser
                </button>
                <ButtonWithLoader
                  size="sm"
                  type="submit"
                  id="dmpSearchSubmit"
                  color="primary"
                  label="Lancer la recherche"
                  loading={loading}
                  loadingLabel="Recherche en cours"
                  disabled={!isValid}
                />
              </Col>
            </Form.Row>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

SearchForm.defaultProps = {
  initialValues: {},
};

SearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  resetParamsAndResults: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
};

export default SearchForm;
