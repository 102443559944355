import React from 'react';
import { put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { encodeIns } from 'dmpconnectjsapp-base/utils/insUtils';
import { clearSession } from 'dmpconnectjsapp-base/actions';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { getInteropCodesFromState, getPracticeLocationSettingFromState } from 'dmpconnectjsapp-base/helpers/accessors';
import { getAccessRightsProps, isTransactionAllowed, transactions } from 'dmpconnectjsapp-base/rules/accessRights';
import { getCurrentPathname as getCurrentPathnameAccessor } from '../helpers';
import {
  removeDocFromPersistantData,
  removeDocumentFromCache, setExpiredSession, setLoginReferer, setModalError,
} from '../actions';
import { explodeUniqueUUid } from '../rules/documentRules';
import { exceptionRoutes } from '../../router';

export const invalidPracticeLocationErrorHandler = function* ({
  error, context, command, modalError, silentError,
}) {
  const { s_apiErrorExtendedInformations, customErrorMessage } = error;

  if (!customErrorMessage && s_apiErrorExtendedInformations.toLowerCase().indexOf('structure introuvable ou inactive') !== -1) {
    const activitySectors = yield select(getInteropCodesFromState, 'activitySectors');
    const { PracticeLocations = {} } = yield select(({ dmpconnect: { [apiSections.CPX_CARD_SECTION]: cpxCard } }) => cpxCard);
    const practiceLocationIndex = yield select(getPracticeLocationSettingFromState);
    const practiceLocation = PracticeLocations[practiceLocationIndex];
    const activitySector = practiceLocation && practiceLocation.s_practiceLocationActivity ? activitySectors.find(as => as.code === practiceLocation.s_practiceLocationActivity) : null;
    // {
    //   type: 'DMPC_COMMAND_FAILURE',
    //     data: {

    // },
    //   command: {
    //     s_commandName: 'hl_getAccessibleDMPList',
    //       s_type: 'LASTAUTORIZATION',
    //       s_date: '20180705',
    //       s_sessionId: '{E3BD3909-1F81-4A7E-ABF6-24E6D48381FB}',
    //       timeout: 600
    //   },
    //   context: {
    //     section: 'accessibleDMPList',
    //       subSection: '899700232871',
    //       params: {}
    //   },
    //   silentError: true
    // }
    yield put({
      type: 'DMPC_COMMAND_FAILURE',
      data: {
        ...error,
        customErrorMessage: (
          // s_practiceLocationActivity(pin):"SA01"
          // s_practiceLocationBillingNumber(pin):"10B0134227"
          // s_practiceLocationHealthcareSettings(pin):""
          // s_practiceLocationName(pin):"HOPITAL DES 3. DAMES"
          // s_practiceLocationStructureId(pin):"10B0134227"
          // eslint-disable-next-line react/jsx-filename-extension
          <>
            <div>Votre situation d&apos;exercice en carte ne vous permet pas d&apos;accéder au DMP (structure fermée ou autre raison). Se rapprocher de votre Ordre et/ou de la CNAM.</div>
            {practiceLocation && (
              <>
                <br />
                <ul>
                  <li key="invalidPracticeLocationName">
                    Structure :
                    {' '}
                    {practiceLocation.s_practiceLocationName}
                  </li>
                  <li key="invalidPracticeLocationId">
                    Identifiant de structure :
                    {' '}
                    {practiceLocation.s_practiceLocationStructureId}
                  </li>
                  <li key="invalidPracticeLocationActivity">
                    Secteur d&apos;activité :
                    {' '}
                    {activitySector.label}
                  </li>
                </ul>
              </>
            )}
          </>
        ),
      },
      command,
      context,
    });
  } else if (!silentError) {
    yield put(setModalError(modalError));
  }
};

export const expiredSessionErrorHandler = function* ({ context }) {
  const pathname = yield select(getCurrentPathnameAccessor);

  if (pathname === '/') {
    yield put(clearSession());
    if (context.section !== apiSections.SESSION_EXISTS_SECTION) {
      window.location.reload();
    }
  } else {
    if (!exceptionRoutes.includes(pathname)) {
      yield put(setLoginReferer(pathname));
    }
    yield put(setExpiredSession(true));
    yield put(push('/'));
  }
};

export const handleDocumentNotFoundError = function* ({
  command, context, modalError,
}) {
  const { subSection } = context;
  const { accessRights } = yield select(getAccessRightsProps);
  const pathname = yield select(getCurrentPathnameAccessor);
  if (isTransactionAllowed(accessRights, transactions.FIND_DOCUMENTS_META)) {
    if (pathname.search('/documents/') === -1) {
      // redirect back to the documents page
      yield put(push(`/dmp/${encodeIns(command.s_ins)}/documents`));
    }
  }

  const { uniqueId, uuid } = explodeUniqueUUid(subSection);

  // remove document from cache
  yield put(removeDocumentFromCache(subSection, command.s_ins));
  yield put(removeDocFromPersistantData(uniqueId, uuid, command.s_ins));

  yield put(setModalError({ ...modalError, logs: false }));
};
