import { Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import {
  apiSections,
  mssLoginTypes,
} from 'dmpconnectjsapp-base/constants';
import { getApiType, getMssEmail } from 'dmpconnectjsapp-base/helpers/accessors';
import { toast } from 'react-toastify';
import commands, { mssSubTypes } from 'dmpconnectjsapp-base/actions/config/commands';
import {
  getError, hasError, isLoading, isReady,
} from 'dmpconnectjsapp-base/helpers/common';
import {
  clearSection, clearSubSection,
  setESConfiguration,
  setMssConfiguration,
  setUserConfiguration,
} from 'dmpconnectjsapp-base/actions';
import { formatPerformMssAuthentication } from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { authenticationTypes } from 'dmpconnectjsapp-base/rules/accessRights';
import ButtonWithLoader                                                                   from '../Common/Form/ButtonWithLoader';
import { generateMessageId, getMssSenderWording, getSendMssEmailAction, MssConfigSchema } from '../../dmpconnect/helpers/mss';
import { getAction, setPersistedAppConfiguration }                                        from '../../dmpconnect/actions';
import checkAccessRights from '../AccessRights/CheckAccessRights';
import ErrorMessage from '../Common/Message/ErrorMessage';
import env from '../../envVariables';
import {
  b64DecodeUnicode,
  isEmailValid,
  objectWithoutTheseKeys,
} from '../../dmpconnect/utils/dataUtils';
import { getXmlElement } from '../../dmpconnect/helpers/xml';
import Loading from '../Common/Loading/Loading';
import ErrorDetails from '../Common/Message/ErrorDetails';
import { createErrorDetails } from '../../dmpconnect/errors';
import GetLogs from '../Common/Logs/GetLogsButton';
import { API_TYPES } from '../../dmpconnect/constants';
import TitleTooltip from '../Common/TitleTooltip';
import { errorActions } from '../../dmpconnect/errors/errorActions';
import userManager from '../../dmpconnect/constants/userManager';

// const mailizDomains = [
//   `pro.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}mssante.fr`,
//   `medecin.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}mssante.fr`,
//   `pharmacien.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}mssante.fr`,
//   `chirurgien-dentiste.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}mssante.fr`,
//   `sage-femme.mssante.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}fr`,
//   `pedicure-podologue.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}mssante.fr`,
//   `masseur-kinesitherapeute.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}mssante.fr`,
//   `infirmier.mssante.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}fr`,
//   `social.mssante.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}fr`,
//   `orthophoniste.${Number(env.REACT_APP_DEBUG) === 1 ? 'formation.' : ''}mssante.fr`,
// ];

// const isMailiz = (email) => {
//   if (MssConfigSchema.isValidSync({ mssEmail: email })) {
//     const parts = email.split('@');
//     return mailizDomains.includes(parts[1]);
//   }
//   return true;
// };

function MssConfig({
  dispatch,
  apiType,
  mssEmail,
  getEmailSection,
  getEmailIsLoading,
  accessRights,
  columnLayout,
  mssActive,
  config,
  getEmailError,
  mssSenderWording,
  mssReplyTo,
  title,
  mssLoginType,
  mssApiType,
  esUser,
  performMssAuthSection,
  mssReceiptNotificationType,

  mssImapServer,
  mssImapPort,
  mssSmtpServer,
  mssSmtpPort,
  esMssCertificate,
  esMssImapCertificate,
  esMssSmtpCertificate,
  esMssWsCertificate,

  mssImapLogin,
  mssImapPasswd,
  mssImapSaslLogin,
  mssSmtpLogin,
  mssSmtpPasswd,
  mssSmtpSaslLogin,
  mssPscAccessToken,

  onSubmit,

  checkConfigFolders,
  checkConfigSmtp,

  advancedContent,
  advanced,
  validatedConfig,

  setImapCertSection,
  setSmtpCertSection,
  setWsCertSection,

  mssOperatorConfig,
  mssOperator,
}) {
  const [email, setEmail] = React.useState(mssEmail);
  const [rpps, setRpps] = React.useState(esUser ? esUser.hpInternalId : accessRights.psId);
  const [senderWording, setSenderWording] = React.useState(mssSenderWording);
  const [submitting, setSubmitting] = React.useState(false);
  const [imapServer, setImapServer] = React.useState(mssImapServer);
  const [imapPort, setImapPort] = React.useState(mssImapPort);
  const [imapLogin, setImapLogin] = React.useState(mssImapLogin);
  const [imapPasswd, setImapPasswd] = React.useState(mssImapPasswd);
  const [imapSaslLogin, setImapSaslLogin] = React.useState(mssImapSaslLogin);
  const [smtpLogin, setSmtpLogin] = React.useState(mssSmtpLogin);
  const [smtpPasswd, setSmtpPasswd] = React.useState(mssSmtpPasswd);
  const [smtpSaslLogin, setSmtpSaslLogin] = React.useState(mssSmtpSaslLogin);
  const [pscAccessToken, setPscAccessToken] = React.useState(mssPscAccessToken);
  const [smtpServer, setSmtpServer] = React.useState(mssSmtpServer);
  const [smtpPort, setSmtpPort] = React.useState(mssSmtpPort);
  const [mssCertificate, setMssCertificate] = React.useState(esMssCertificate);
  const [mssSmtpCertificate, setMssSmtpCertificate] = React.useState(esMssSmtpCertificate);
  const [mssImapCertificate, setMssImapCertificate] = React.useState(esMssImapCertificate);
  const [mssWsCertificate, setMssWsCertificate] = React.useState(esMssWsCertificate);
  const [emails, setEmails] = React.useState([]);
  const [checkConfigImapErrorDetails, toggleCheckConfigImapErrorDetails] = React.useState(false);
  const [checkConfigSmtpErrorDetails, toggleCheckConfigSmtpErrorDetails] = React.useState(false);
  const [changed, setChanged] = React.useState(false);

  const [waitForCerts, setWaitForCerts] = React.useState(false);
  const [waitForImapCert, setWaitForImapCert] = React.useState(false);
  const [waitForSmtpCert, setWaitForSmtpCert] = React.useState(false);
  const [waitForWsCert, setWaitForWsCert] = React.useState(false);

  const mssOperatorRef = React.useRef(mssOperator);
  const mssLoginTypeRef = React.useRef(mssLoginType);
  const mssApiTypeRef = React.useRef(mssApiType);
  const mssReplyToRef = React.useRef(mssReplyTo);
  const mssReceiptNotificationTypeRef = React.useRef(mssReceiptNotificationType);

  React.useEffect(() => {
    setPscAccessToken(mssPscAccessToken);
  }, [mssPscAccessToken]);

  React.useEffect(() => {
    if (mssOperatorConfig) {
      const { dev = {}, prod = {} } = mssOperatorConfig;

      let configImapServer;
      let configImapPort;
      let configSmtpServer;
      let configSmtpPort;

      if (Number(env.REACT_APP_PRODUCTON_MODE) === 1) {
        configImapServer = prod.imapServer;
        configImapPort = prod.imapPort;
        configSmtpServer = prod.smtpServer;
        configSmtpPort = prod.smtpPort;
      } else {
        configImapServer = dev.imapServer;
        configImapPort = dev.imapPort;
        configSmtpServer = dev.smtpServer;
        configSmtpPort = dev.smtpPort;
      }

      setImapServer(configImapServer);
      setImapPort(configImapPort);
      setSmtpServer(configSmtpServer);
      setSmtpPort(configSmtpPort);
    }
  }, [mssOperatorConfig]);

  const showLoginPassword = React.useMemo(() => {
    const { showImapLogin = true, enableLoginPassword } = mssOperatorConfig || {};
    if (enableLoginPassword !== undefined) {
      return enableLoginPassword;
    }
    return showImapLogin;
  }, [mssOperatorConfig]);

  React.useEffect(() => {
    if (email) {
      if (!showLoginPassword) {
        setImapLogin(email);
        setSmtpLogin(email);
      }
    }
  }, [email, showLoginPassword]);

  React.useEffect(() => {
    if (
      (!!email && email !== mssEmail)
      || (!!rpps && rpps !== (esUser ? esUser.hpInternalId : accessRights.psId))
      || (!!senderWording && senderWording !== mssSenderWording)
      || (!!imapServer && imapServer !== mssImapServer)
      || (!!imapPort && imapPort !== mssImapServer)
      || (!!imapLogin && imapLogin !== mssImapLogin)
      || (!!imapPasswd && imapPasswd !== mssImapPasswd)
      || (!!imapSaslLogin && imapSaslLogin !== mssImapSaslLogin)
      || (!!smtpServer && smtpServer !== mssSmtpServer)
      || (!!smtpPort && smtpPort !== mssSmtpPort)
      || (!!smtpLogin && smtpLogin !== mssSmtpLogin)
      || (!!smtpPasswd && smtpPasswd !== mssSmtpPasswd)
      || (!!smtpSaslLogin && smtpSaslLogin !== mssSmtpSaslLogin)
      || (!!pscAccessToken && pscAccessToken !== mssPscAccessToken)
      || (!!mssCertificate && mssCertificate !== esMssCertificate)
      || (!!mssWsCertificate && mssWsCertificate !== esMssWsCertificate)
      || (!!mssImapCertificate && mssImapCertificate !== esMssImapCertificate)
      || (!!mssSmtpCertificate && mssSmtpCertificate !== esMssSmtpCertificate)
    ) {
      setChanged(true);
    }
  }, [
    email, mssEmail,
    rpps, esUser, accessRights.psId,
    senderWording, mssSenderWording,
    imapServer, mssImapServer,
    imapPort, mssImapPort,
    imapLogin, mssImapLogin,
    imapPasswd, mssImapPasswd,
    imapSaslLogin, mssImapSaslLogin,
    smtpServer, mssSmtpServer,
    smtpPort, mssSmtpPort,
    smtpLogin, mssSmtpLogin,
    smtpPasswd, mssSmtpPasswd,
    smtpSaslLogin, mssSmtpSaslLogin,
    pscAccessToken, mssPscAccessToken,
    mssCertificate, esMssCertificate,
    mssImapCertificate, esMssImapCertificate,
    mssSmtpCertificate, esMssSmtpCertificate,
    mssWsCertificate, esMssWsCertificate,
  ]);

  React.useEffect(() => {
    if (mssOperator && mssOperatorRef.current !== mssOperator) {
      setChanged(true);
      mssOperatorRef.current = mssOperator;
      setImapLogin('');
      setImapPasswd('');
      setImapSaslLogin('');
      setSmtpLogin('');
      setSmtpPasswd('');
      setSmtpSaslLogin('');
    }
  }, [mssOperator]);

  React.useEffect(() => {
    if (mssLoginTypeRef && mssLoginTypeRef.current !== mssLoginType) {
      setChanged(true);
      mssLoginTypeRef.current = mssLoginType;
    }
  }, [mssLoginType]);

  React.useEffect(() => {
    if (mssApiTypeRef && mssApiTypeRef.current !== mssApiType) {
      setChanged(true);
      mssApiTypeRef.current = mssApiType;
    }
  }, [mssApiType]);

  React.useEffect(() => {
    if (mssReceiptNotificationTypeRef && mssReceiptNotificationTypeRef.current !== mssReceiptNotificationType) {
      setChanged(true);
      mssReceiptNotificationTypeRef.current = mssReceiptNotificationType;
    }
  }, [mssReceiptNotificationType]);

  React.useEffect(() => {
    if (mssReplyToRef && mssReplyToRef.current !== mssReplyTo) {
      setChanged(true);
      mssReplyToRef.current = mssReplyTo;
    }
  }, [mssReplyTo]);

  React.useEffect(() => {
    if (isReady(getEmailSection)) {
      const { s_answerBodyInBase64 } = getEmailSection;
      const body = b64DecodeUnicode(s_answerBodyInBase64);
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(body, 'text/xml');

      const emailsNodeList = getXmlElement(xmlDoc, 'emails', 0).childNodes;

      if (emailsNodeList) {
        const emailsList = [...emailsNodeList.values()].map(e => e.nodeValue);
        if (emailsList.length > 1) {
          setEmails(emailsList);
        } else if (emailsList.length === 1) {
          setEmail(emailsList[0]);
        }
      }
    }
  }, [getEmailSection]);


  React.useEffect(() => {
    dispatch(clearSection(apiSections.MSS_GET_FOLDERS));
    dispatch(clearSection(apiSections.MSS_SEND_SMTP_EMAIL));
  }, []);

  React.useEffect(() => {
    if (
      (mssApiType === mssSubTypes.WEB || isReady(checkConfigSmtp))
      && isReady(checkConfigFolders)
    ) {
      // set validatedConfig for this account to true
      dispatch(setMssConfiguration(mssEmail, true));
    }
  }, [isReady(checkConfigFolders), isReady(checkConfigSmtp)]);


  const checkConfig = () => {
    if (
      mssLoginType !== mssLoginTypes.PSC
        || (mssLoginType === mssLoginTypes.PSC && userManager.loggedIn)
    ) {
      // set validatedConfig for this account to false
      dispatch(setMssConfiguration(mssEmail, false));
      dispatch(clearSection(apiSections.MSS_GET_FOLDERS));
      dispatch(clearSection(apiSections.MSS_SEND_SMTP_EMAIL));
      dispatch(getAction(
        commands.getFoldersMSS,
        apiSections.MSS_GET_FOLDERS,
        email,
        {
          synchronous: true,
          subConfig: mssApiType,
          contextExtra: { mssApiType, save: false, forceAction: errorActions.NONE },
          subSection: 'check-config',
          silentError: true,
        },
      ));

      const emailContent = {
        sender: email,
        title: 'test d\'envoi d\'un message depuis Efficience',
        messageContent: 'test d\'envoi d\'un message depuis Efficience',
        recipients: email,
        isHtml: false,
        replyTo: mssReplyTo,
        senderWording: mssSenderWording,
        messageId: generateMessageId(),
        attachments: [],
        otherAttachments: [],
      };
      dispatch(getSendMssEmailAction(mssApiType, mssApiType, emailContent, esUser, undefined, 'check-config'));
    }
  };


  React.useEffect(() => {
    if (waitForWsCert) {
      dispatch(getAction(
        commands.setMssEsWebServiceCertificate,
        apiSections.MSS_SET_WS_CERT,
        mssCertificate !== esMssCertificate && !mssWsCertificate ? mssCertificate : mssWsCertificate,
        {
          silentError: true,
        },
      ));
    }
  }, [waitForWsCert]);

  React.useEffect(() => {
    if (waitForImapCert) {
      dispatch(getAction(
        commands.setMssEsImapCertificate,
        apiSections.MSS_SET_IMAP_CERT,
        mssCertificate !== esMssCertificate && !mssImapCertificate ? mssCertificate : mssImapCertificate,
        {
          silentError: true,
        },
      ));
    }
  }, [waitForImapCert]);

  React.useEffect(() => {
    if (waitForSmtpCert) {
      dispatch(getAction(
        commands.setMssEsSmtpCertificate,
        apiSections.MSS_SET_SMTP_CERT,
        mssCertificate !== esMssCertificate && !mssSmtpCertificate ? mssCertificate : mssSmtpCertificate,
        {
          silentError: true,
        },
      ));
    }
  }, [waitForSmtpCert]);

  React.useEffect(() => {
    const wsOk = !waitForWsCert || (waitForWsCert && isReady(setWsCertSection));
    const imapOk = !waitForImapCert || (waitForImapCert && isReady(setImapCertSection));
    const smtpOk = !waitForSmtpCert || (waitForSmtpCert && isReady(setSmtpCertSection));

    const wsKO = waitForWsCert && hasError(setWsCertSection);
    const imapKO = waitForImapCert && hasError(setImapCertSection);
    const smtpKO = waitForSmtpCert && hasError(setSmtpCertSection);

    if (waitForCerts && wsOk && imapOk && smtpOk) {
      dispatch(setESConfiguration('esMssImapCertificate', mssImapCertificate));
      dispatch(setESConfiguration('esMssSmtpCertificate', mssSmtpCertificate));
      dispatch(setESConfiguration('esMssWsCertificate', mssWsCertificate));


      checkConfig();
      setSubmitting(false);
      setWaitForCerts(false);

      setWaitForImapCert(false);
      setWaitForSmtpCert(false);
      setWaitForWsCert(false);

      setChanged(false);
    }

    if (waitForCerts && (wsKO || imapKO || smtpKO)) {
      setSubmitting(false);
      setWaitForCerts(false);

      setWaitForImapCert(false);
      setWaitForSmtpCert(false);
      setWaitForWsCert(false);
    }
  }, [
    waitForWsCert, waitForImapCert, waitForSmtpCert,
    setWsCertSection, setImapCertSection, setSmtpCertSection,
    waitForCerts,
  ]);

  const submitMss = () => {
    setSubmitting(true);

    onSubmit();
    dispatch(clearSection(apiSections.MSS_GET_EMAIL));
    dispatch(clearSection(apiSections.MSS_SET_SMTP_CERT));
    dispatch(clearSection(apiSections.MSS_SET_IMAP_CERT));
    dispatch(clearSection(apiSections.MSS_SET_WS_CERT));
    dispatch(clearSubSection(apiSections.MSS_GET_FOLDERS, 'check-config'));
    dispatch(clearSubSection(apiSections.MSS_SEND_SMTP_EMAIL, 'check-config'));
    dispatch(setUserConfiguration(accessRights.psId, {
      mssEmail: email,
      mssSenderWording: senderWording,
      mssReplyTo,
    }));

    dispatch(setPersistedAppConfiguration('mssOtpChannelType', ''));
    dispatch(setMssConfiguration('mssImapServer', imapServer || ''));
    dispatch(setMssConfiguration('mssImapPort', imapPort || ''));
    dispatch(setMssConfiguration('mssImapLogin', imapLogin || ''));
    dispatch(setMssConfiguration('mssImapPasswd', imapPasswd || ''));
    dispatch(setMssConfiguration('mssImapSaslLogin', imapSaslLogin || ''));
    dispatch(setMssConfiguration('mssSmtpServer', smtpServer || ''));
    dispatch(setMssConfiguration('mssSmtpPort', smtpPort || ''));
    dispatch(setMssConfiguration('mssSmtpLogin', smtpLogin || ''));
    dispatch(setMssConfiguration('mssSmtpPasswd', smtpPasswd || ''));
    dispatch(setMssConfiguration('mssSmtpSaslLogin', smtpSaslLogin || ''));

    dispatch(setMssConfiguration('mssPscAccessToken', pscAccessToken));

    dispatch(setESConfiguration('esMssCertificate', mssCertificate));

    let waitForSetCertsResults = false;

    if (
      [API_TYPES.WS, API_TYPES.JSPOST].includes(apiType)
      && (
        mssImapCertificate !== esMssImapCertificate
        || (mssCertificate !== esMssCertificate && !mssImapCertificate)
      )
    ) {
      setWaitForImapCert(true);
      waitForSetCertsResults = true;
    }

    if (
      [API_TYPES.WS, API_TYPES.JSPOST].includes(apiType)
      && (
        mssSmtpCertificate !== esMssSmtpCertificate
        || (mssCertificate !== esMssCertificate && !mssSmtpCertificate)
      )
    ) {
      setWaitForSmtpCert(true);
      waitForSetCertsResults = true;
    }

    if (
      [API_TYPES.WS, API_TYPES.JSPOST].includes(apiType)
      && (
        mssWsCertificate !== esMssWsCertificate
        || (mssCertificate !== esMssCertificate && !mssWsCertificate)
      )
    ) {
      setWaitForWsCert(true);
      waitForSetCertsResults = true;
    }

    toast(
      'La configuration MSS à été mise à jour',
      {
        type: 'success',
        position: 'top-right',
        closeOnClick: true,
        autoClose: true,
        hideProgressBar: true,
      },
    );
    setWaitForCerts(waitForSetCertsResults);
    if (!waitForSetCertsResults) {
      checkConfig();
      setSubmitting(false);
      setChanged(false);
    }
  };

  const performMssAuthentication = () => {
    submitMss();
    dispatch(getAction(
      commands.performMssAuthentication,
      apiSections.MSS_PERFORM_AUTHENTICATION,
      formatPerformMssAuthentication(rpps),
    ));
  };

  const getEmailFromCPS = () => {
    dispatch(setUserConfiguration(accessRights.psId, { mssEmail: '' }));
    setEmail('');
    setEmails([]);
    dispatch(getAction(
      commands.getEmailAdress,
      apiSections.MSS_GET_EMAIL,
      accessRights.psId,
      { silentError: true },
    ));
  };

  return (
    <>
      <div>
        {title && (
        <h5 className="mb-4">{title}</h5>
        )}

        {getEmailError && (
        <ErrorMessage
          error={getEmailError}
          message={(
            <>
              L&apos;utilisation de la messagerie MSS nécessite un compte Mailiz.
              <br />
              Si vous n&apos;en disposez pas, vous pouvez en créer un depuis cette adresse :
              {' '}
              <a
                href={Number(env.REACT_APP_PRODUCTON_MODE) === 1 ? 'https://mailiz.mssante.fr/' : 'https://mailiz.formation.mssante.fr/'}
                target="_blank"
                rel="noopener noreferrer"
              >
                Mailiz
              </a>
            </>
              )}
          showDetails
        />
        )}

        {config && (
        <>
          {mssApiType === mssSubTypes.IMAP && (showLoginPassword || mssOperator === 'custom') && (
          <>
            <div className="admin-content-divider" />
            <h6 className="mt-3">IMAP/SMTP</h6>
            <Form.Group>
              <Form.Label>
                Imap login
              </Form.Label>
              <Form.Control
                className="w-full"
                onChange={event => setImapLogin(event.target.value)}
                name="mssImapLogin"
                value={imapLogin}
                disabled={!mssActive}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Imap Password
              </Form.Label>
              <Form.Control
                className="w-full"
                onChange={event => setImapPasswd(event.target.value)}
                name="mssImapPasswd"
                value={imapPasswd}
                disabled={!mssActive}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Imap SaslLogin
              </Form.Label>
              <Form.Control
                className="w-full"
                onChange={event => setImapSaslLogin(event.target.value)}
                name="mssImapSaslLogin"
                value={imapSaslLogin}
                disabled={!mssActive}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Smtp login
              </Form.Label>
              <Form.Control
                className="w-full"
                onChange={event => setSmtpLogin(event.target.value)}
                name="mssSmtpLogin"
                value={smtpLogin}
                disabled={!mssActive}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Smtp Password
              </Form.Label>
              <Form.Control
                className="w-full"
                onChange={event => setSmtpPasswd(event.target.value)}
                name="mssSmtpPasswd"
                value={smtpPasswd}
                disabled={!mssActive}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Smtp SaslLogin
              </Form.Label>
              <Form.Control
                className="w-full"
                onChange={event => setSmtpSaslLogin(event.target.value)}
                name="mssSmtpSaslLogin"
                value={smtpSaslLogin}
                disabled={!mssActive}
              />
            </Form.Group>

            {mssLoginType === mssLoginTypes.PSC && (
            <>
              <div className="admin-content-divider" />
              <h6 className="mt-3">Pro Santé Connect</h6>
              <Form.Group>
                <Form.Label>
                  JWT Access Token
                </Form.Label>
                <Form.Control
                  className="w-full"
                  onChange={event => setPscAccessToken(event.target.value)}
                  name="mssPscAccessToken"
                  value={pscAccessToken}
                  disabled={!mssActive}
                />
              </Form.Group>
            </>
            )}
          </>
          )}
          {mssLoginType === mssLoginTypes.CERT && apiType !== API_TYPES.REST && (
          <>
            <div className="admin-content-divider" />
            <h6 className="mt-3">Authentification par certificat logiciel</h6>
            <Form.Group>
              <Form.Label>
                Certificat MSS global
              </Form.Label>
              <Form.Control
                className="w-full"
                as="textarea"
                onChange={event => setMssCertificate(event.target.value)}
                name="esMssCertificate"
                value={mssCertificate}
                disabled={!mssActive}
                rows={4}
              />
              <Form.Text className="small">
                Certificat utilisé pour les appels aux Web Services Mailiz ou pour les appels IMAP/SMTP.
              </Form.Text>
            </Form.Group>
            {mssApiType === mssSubTypes.IMAP && (
              <>
                <Form.Group>
                  <Form.Label>
                    Certificat MSS IMAP
                  </Form.Label>
                  <Form.Control
                    className="w-full"
                    as="textarea"
                    onChange={event => setMssImapCertificate(event.target.value)}
                    name="esMssImapCertificate"
                    value={mssImapCertificate}
                    disabled={!mssActive}
                    rows={4}
                  />
                  <Form.Text className="small">
                    Remplace le certificat MSS global pour tous les appels IMAP.
                  </Form.Text>
                  {hasError(setImapCertSection) && (
                    <ErrorMessage
                      error={getError(setImapCertSection)}
                      message={(
                        <>
                          Une erreur s&apos;est produite lors de l&apos;enregistrement du certificat.
                          {getError(setImapCertSection).s_apiErrorExtendedInformations.indexOf('Unknown command:') !== -1 && (
                            <>
                              <br />
                              Il faudra vous reconnecter pour que la modification soit prise en compte.
                            </>
                          )}
                        </>
                      )}
                      showDetails
                    />
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Certificat MSS SMTP
                  </Form.Label>
                  <Form.Control
                    className="w-full"
                    as="textarea"
                    onChange={event => setMssSmtpCertificate(event.target.value)}
                    name="esMssStmpCertificate"
                    value={mssSmtpCertificate}
                    disabled={!mssActive}
                    rows={4}
                  />
                  <Form.Text className="small">
                    Remplace le certificat MSS global pour tous les appels SMTP.
                  </Form.Text>
                  {hasError(setSmtpCertSection) && (
                    <ErrorMessage
                      error={getError(setSmtpCertSection)}
                      message={(
                        <>
                          Une erreur s&apos;est produite lors de l&apos;enregistrement du certificat.
                          {getError(setSmtpCertSection).s_apiErrorExtendedInformations.indexOf('Unknown command:') !== -1 && (
                            <>
                              <br />
                              Il faudra vous reconnecter pour que la modification soit prise en compte.
                            </>
                          )}
                        </>
                      )}
                      showDetails
                    />
                  )}
                </Form.Group>
              </>
            )}
            {mssApiType === mssSubTypes.WEB && (
              <Form.Group>
                <Form.Label>
                  Certificat MSS WebService
                </Form.Label>
                <Form.Control
                  className="w-full"
                  as="textarea"
                  onChange={event => setMssWsCertificate(event.target.value)}
                  name="esMssWsCertificate"
                  value={mssWsCertificate}
                  disabled={!mssActive}
                  rows={4}
                />
                <Form.Text className="small">
                  Remplace le certificat MSS global pour tous les appels aux WebService Mailiz.
                </Form.Text>
                {hasError(setWsCertSection) && (
                  <ErrorMessage
                    error={getError(setWsCertSection)}
                    message={(
                      <>
                        Une erreur s&apos;est produite lors de l&apos;enregistrement du certificat.
                        {getError(setWsCertSection).s_apiErrorExtendedInformations.indexOf('Unknown command:') !== -1 && (
                          <>
                            <br />
                            Il faudra vous reconnecter pour que la modification soit prise en compte.
                          </>
                        )}
                      </>
                    )}
                    showDetails
                  />
                )}
              </Form.Group>
            )}
          </>
          )}
        </>
        )}
        <div className="admin-content-divider" />
        <h6 className="mt-3">Configuration du compte</h6>
        {mssLoginType === mssLoginTypes.CPX && emails.length > 1 ? (
          <Form.Group as={columnLayout ? Row : undefined}>
            <Form.Label column={columnLayout} sm="4">
              Adresse Email
            </Form.Label>
            <div className={columnLayout ? 'col-sm-8' : undefined}>
              <Form.Control
                className="w-full"
                as="select"
                id="mssEmail"
                disabled={!mssActive}
                onChange={event => setEmail(event.target.value)}
                name="mssEmail"
                value={email}
                isInvalid={!isEmailValid(email)}
              >
                {emails.map(e => <option value={e}>{e}</option>)}
              </Form.Control>
              {!email && <Form.Control.Feedback type="invalid">Ce champs est requis</Form.Control.Feedback>}
              {!!email && !isEmailValid(email) && (
                <Form.Control.Feedback type="invalid">Adresse email invalide</Form.Control.Feedback>
              )}
            </div>
            {/* <Form.Control.Feedback type="invalid"> */}
            {/* {errors.mssEmail} */}
            {/* </Form.Control.Feedback> */}
          </Form.Group>
        ) : (
          <Form.Group as={columnLayout ? Row : undefined}>
            <Form.Label column={columnLayout} sm="4">
              Adresse Email
            </Form.Label>
            <div className={columnLayout ? 'col-sm-8' : undefined}>
              <Form.Control
                className="w-full"
                id="mssEmail"
                disabled={!mssActive}
                onChange={event => setEmail(event.target.value)}
                name="mssEmail"
                value={email}
                isInvalid={!isEmailValid(email)}
              />
              {!email && <Form.Control.Feedback type="invalid">Ce champs est requis</Form.Control.Feedback>}
              {!!email && !isEmailValid(email) && (
                <Form.Control.Feedback type="invalid">Adresse email invalide</Form.Control.Feedback>
              )}
            </div>

            {/* {mssOperator === mssOperators.MAILIZ && !isMailiz(email) && ( */}
            {/* <Alert type="warning" className="mt-2"> */}
            {/*  L&apos;adresse email ne semble pas être une adresse Mailiz valide. */}
            {/* </Alert> */}
            {/* )} */}
            {/* <Form.Control.Feedback type="invalid"> */}
            {/* {errors.mssEmail} */}
            {/* </Form.Control.Feedback> */}
          </Form.Group>
        )}
        {mssApiType === mssSubTypes.WEB && mssLoginType === mssLoginTypes.CERT && (
        <Form.Group controlId="rpps">
          <Form.Label>
            Identifiant national (RPPS)
          </Form.Label>
          <Form.Control
            value={rpps}
            onChange={event => setRpps(event.target.value)}
            disabled={!mssActive}
          />
        </Form.Group>
        )}
        {!config && (
        <>
          {mssLoginType === mssLoginTypes.CERT && mssApiType === mssSubTypes.WEB ? (
            <Form.Group>
              <ButtonWithLoader
                disabled={!rpps}
                label="Se connecter"
                loading={isLoading(performMssAuthSection)}
                onClick={() => performMssAuthentication()}
              />
            </Form.Group>
          ) : (
            <Form.Group>
              <ButtonWithLoader
                disabled={!mssActive || !MssConfigSchema.isValidSync({
                  mssEmail: email,
                })}
                label="Se connecter"
                loading={submitting}
                onClick={() => submitMss()}
              />
            </Form.Group>
          )}
        </>
        )}

        {accessRights.authenticationType === authenticationTypes.DIRECT && (
        <>
          {!config && <div className="typography-big-text text-gray-300 mb-3">ou</div>}

          <Form.Group>
            <ButtonWithLoader
              disabled={!mssActive}
              loading={getEmailIsLoading}
              label="Obtenir l'email depuis la carte CPS"
              loadingLabel=""
              variant="outline-secondary"
              onClick={() => getEmailFromCPS()}
              className="mr-2"
            />
          </Form.Group>
        </>
        )}

        {config && (
        <>
          <Form.Group as={columnLayout ? Row : undefined}>

            <Form.Label column={columnLayout} sm="4">
              Libellé de l&apos;expéditeur
              <TitleTooltip
                id="sender-wording-tooltip"
                text={(
                  <div className="text-left">
                    <h6 className="text-white">Exemples :</h6>
                    <ul>
                      <li>Boite mail personnelle professionnelle : Dr Marie MARTIN + optionnellement le nom du service / de l&apos;entité fonctionnelle</li>
                      <li>Boite mail organisationnelle : Hôpital A – Service Cardiologie</li>
                    </ul>
                  </div>
                )}
              >
                <i className="ic-info ml-2" />
              </TitleTooltip>
            </Form.Label>
            <div className={columnLayout ? 'col-sm-8' : undefined}>
              <Form.Control
                className="w-full"
                id="mssSenderWording"
                disabled={!mssActive}
                onChange={event => setSenderWording(event.target.value)}
                name="mssSenderWording"
                value={senderWording}
              />
            </div>
          </Form.Group>

          {advancedContent}

          {(isLoading(checkConfigFolders) || isReady(checkConfigFolders) || hasError(checkConfigFolders)) && (
          <div className="mb-4">
            <h6 className="mt-3">Test de la configuration</h6>
            <div>
              {isLoading(checkConfigFolders) && (
              <Loading size="sm" centered={false} message="Test d'accès à la boite de messagerie" />
              )}
              {isReady(checkConfigFolders)
                    && (
                      mssApiType === mssSubTypes.WEB
                      || (checkConfigFolders.SubFolder && checkConfigFolders.SubFolder.length > 0)
                    )
                    && (
                    <div className="d-flex flex-row align-items-center justify-content-start">
                      <i className="alert-icon alert-icon-success mr-2" />
                      <span>Test d&apos;accès à la boite de messagerie - OK</span>
                    </div>
                    )}
              {(
                hasError(checkConfigFolders)
                    || (
                      isReady(checkConfigFolders)
                      && checkConfigFolders.SubFolder
                      && checkConfigFolders.SubFolder.length === 0
                    )) && (
                    <div className="d-flex flex-row align-items-center justify-content-start">
                      <i className="alert-icon alert-icon-danger mr-2" />
                      <span>Test d&apos;accès à la boite de messagerie - Erreur</span>
                      <Button
                        className="ml-3 mb-2"
                        variant="outline-danger"
                        size="sm"
                        onClick={() => toggleCheckConfigImapErrorDetails(true)}
                      >
                        Afficher le détail de l&apos;erreur
                      </Button>
                      {checkConfigImapErrorDetails && (
                      <Modal show onHide={() => toggleCheckConfigImapErrorDetails(false)}>
                        <Modal.Header closeButton>Erreur détaillée</Modal.Header>
                        <Modal.Body>
                          {hasError(checkConfigFolders) ? (
                            <ErrorDetails
                              details={[createErrorDetails(
                                'erreur',
                                objectWithoutTheseKeys(
                                  { detailsType: 'error', ...getError(checkConfigFolders) },
                                  ['customErrorMessage'],
                                ),
                              )]}
                              error={getError(checkConfigFolders)}
                            />
                          ) : (
                            <>
                              Impossible de récupérer la liste des dossiers de la boite de messagerie.
                              {' '}
                              <br />
                              Cela peut indiquer un problème avec le compte de messagerie ou avec l&apos;accès au serveur.
                            </>
                          )}
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-between">
                          <GetLogs
                            onOpen={() => toggleCheckConfigImapErrorDetails(false)}
                            error={objectWithoutTheseKeys(
                              { detailsType: 'error', ...getError(checkConfigFolders) },
                              ['customErrorMessage'],
                            )}
                          />
                          <Button
                            variant="outline-secondary"
                            onClick={() => toggleCheckConfigImapErrorDetails(false)}
                          >
                            Fermer
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      )}
                    </div>
              )}
            </div>
            <div>
              {isLoading(checkConfigSmtp) && (
              <Loading size="sm" centered={false} message="Test d'envoi d'un message" />
              )}
              {isReady(checkConfigSmtp) && (
              <div className="d-flex flex-row align-items-center justify-content-start">
                <i className="alert-icon alert-icon-success mr-2" />
                <span>Test d&apos;envoi d&apos;un message - OK</span>
              </div>
              )}
              {hasError(checkConfigSmtp) && (
              <div className="d-flex flex-row align-items-center justify-content-start">
                <i className="alert-icon alert-icon-danger mr-2" />
                <span>Test d&apos;envoi d&apos;un message - Erreur</span>
                <Button
                  className="ml-3"
                  variant="outline-danger"
                  size="sm"
                  onClick={() => toggleCheckConfigSmtpErrorDetails(true)}
                >
                  Afficher le détail de l&apos;erreur
                </Button>
                {checkConfigSmtpErrorDetails && (
                <Modal show onHide={() => toggleCheckConfigSmtpErrorDetails(false)}>
                  <Modal.Header closeButton>Erreur détaillée</Modal.Header>
                  <Modal.Body>
                    <ErrorDetails
                      details={[createErrorDetails(
                        'erreur',
                        objectWithoutTheseKeys(
                          { detailsType: 'error', ...getError(checkConfigSmtp) },
                          ['customErrorMessage'],
                        ),
                      )]}
                      error={getError(checkConfigSmtp)}
                    />
                  </Modal.Body>
                  <Modal.Footer className="d-flex justify-content-between">
                    <GetLogs
                      onOpen={() => toggleCheckConfigSmtpErrorDetails(false)}
                      error={objectWithoutTheseKeys(
                        { detailsType: 'error', ...getError(checkConfigFolders) },
                        ['customErrorMessage'],
                      )}
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={() => toggleCheckConfigSmtpErrorDetails(false)}
                    >
                      Fermer
                    </Button>
                  </Modal.Footer>
                </Modal>
                )}
              </div>
              )}
            </div>
          </div>
          )}
          <Form.Group>
            <ButtonWithLoader
              disabled={(!changed && validatedConfig) || submitting || !isEmailValid(email)}
              label={mssLoginType === mssLoginTypes.PSC && !userManager.loggedIn ? 'Enregistrer la configuration' : 'Valider la configuration'}
              loading={submitting || isLoading(checkConfigFolders) || isLoading(checkConfigSmtp)}
              onClick={() => submitMss()}
            />
          </Form.Group>
        </>
        )}
      </div>
    </>
  );
}

MssConfig.propTypes = {
  dispatch: PropTypes.func.isRequired,
  apiType: PropTypes.string.isRequired,
  getEmailIsLoading: PropTypes.bool,
  getEmailError: PropTypes.object,
  performMssAuthSection: PropTypes.object,
  esUser: PropTypes.object,
  mssEmail: PropTypes.string,
  mssSenderWording: PropTypes.string,
  mssReplyTo: PropTypes.string,
  accessRights: PropTypes.object.isRequired,
  columnLayout: PropTypes.bool,
  mssActive: PropTypes.bool,
  validatedConfig: PropTypes.bool,
  config: PropTypes.bool,
  title: PropTypes.string,
  mssLoginType: PropTypes.string.isRequired,
  mssApiType: PropTypes.string.isRequired,
  mssImapServer: PropTypes.string,
  mssImapPort: PropTypes.string,
  mssSmtpServer: PropTypes.string,
  mssSmtpPort: PropTypes.string,
  esMssCertificate: PropTypes.string,
  esMssImapCertificate: PropTypes.string,
  esMssSmtpCertificate: PropTypes.string,
  esMssWsCertificate: PropTypes.string,
  mssImapLogin: PropTypes.string,
  mssImapPasswd: PropTypes.string,
  mssImapSaslLogin: PropTypes.string,
  mssSmtpLogin: PropTypes.string,
  mssSmtpPasswd: PropTypes.string,
  mssSmtpSaslLogin: PropTypes.string,
  mssPscAccessToken: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  getEmailSection: PropTypes.object,
  checkConfigFolders: PropTypes.object,
  checkConfigSmtp: PropTypes.object,
  advancedContent: PropTypes.node,
  advanced: PropTypes.bool,
  setImapCertSection: PropTypes.object,
  setSmtpCertSection: PropTypes.object,
  setWsCertSection: PropTypes.object,
  mssOperatorConfig: PropTypes.object,
};
MssConfig.defaultProps = {
  esUser: undefined,
  getEmailIsLoading: false,
  getEmailError: {},
  performMssAuthSection: {},
  mssEmail: '',
  mssSenderWording: '',
  mssReplyTo: '',
  columnLayout: true,
  mssActive: false,
  validatedConfig: false,
  config: true,
  title: '',
  mssImapServer: '',
  mssImapPort: '',
  mssSmtpServer: '',
  mssSmtpPort: '',
  esMssCertificate: '',
  esMssImapCertificate: '',
  esMssSmtpCertificate: '',
  esMssWsCertificate: '',
  mssImapLogin: '',
  mssImapPasswd: '',
  mssImapSaslLogin: '',
  mssSmtpLogin: '',
  mssSmtpPasswd: '',
  mssSmtpSaslLogin: '',
  mssPscAccessToken: '',
  getEmailSection: {},
  checkConfigFolders: undefined,
  checkConfigSmtp: undefined,
  advancedContent: undefined,
  advanced: false,
  setImapCertSection: {},
  setSmtpCertSection: {},
  setWsCertSection: {},
  mssOperatorConfig: undefined,
};

function mapStateToProps(state) {
  const mssEmail = getMssEmail(state);
  const {
    dmpconnect: {
      [apiSections.MSS_GET_EMAIL]: getEmailSection,
      [apiSections.MSS_PERFORM_AUTHENTICATION]: performMssAuth,
      [apiSections.MSS_GET_FOLDERS]: {
        'check-config': checkConfigFolders,
      },
      [apiSections.MSS_SEND_SMTP_EMAIL]: {
        'check-config': checkConfigSmtp,
      },
      [apiSections.MSS_SET_IMAP_CERT]: setImapCertSection,
      [apiSections.MSS_SET_SMTP_CERT]: setSmtpCertSection,
      [apiSections.MSS_SET_WS_CERT]: setWsCertSection,
    },
    dmpconnectUser: { esUser },
    dmpconnectESConfiguration: {
      esMssCertificate,
      esMssImapCertificate,
      esMssSmtpCertificate,
      esMssWsCertificate,
    },
    dmpconnectMSSConfiguration: {
      mssImapServer,
      mssImapPort,
      mssSmtpServer,
      mssSmtpPort,
      mssImapLogin,
      mssImapPasswd,
      mssImapSaslLogin,
      mssSmtpLogin,
      mssSmtpPasswd,
      mssSmtpSaslLogin,
      mssPscAccessToken,
      [mssEmail]: validatedConfig,
    },
  } = state;

  return {
    apiType: getApiType(state),
    getEmailIsLoading: isLoading(getEmailSection),
    getEmailError: getError(getEmailSection),
    mssEmail: getMssEmail(state),
    getEmailSection,
    mssImapServer,
    mssImapPort,
    mssSmtpServer,
    mssSmtpPort,
    mssImapLogin,
    mssImapPasswd,
    mssImapSaslLogin,
    mssSmtpLogin,
    mssSmtpPasswd,
    mssSmtpSaslLogin,
    mssPscAccessToken,
    esUser,
    performMssAuthSection: performMssAuth,
    esMssCertificate,
    esMssImapCertificate,
    esMssSmtpCertificate,
    esMssWsCertificate,
    mssSenderWording: getMssSenderWording(state),
    checkConfigFolders,
    checkConfigSmtp,
    validatedConfig,
    setImapCertSection,
    setSmtpCertSection,
    setWsCertSection,
  };
}

export default checkAccessRights(connect(mapStateToProps)(MssConfig));
