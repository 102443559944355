import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getConfigurationValue, getInsiConfig } from 'dmpconnectjsapp-base/helpers/accessors';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { hasError, isLoading, isReady } from 'dmpconnectjsapp-base/helpers/common';
import Button from 'react-bootstrap/Button';
import { clearSection } from 'dmpconnectjsapp-base/actions';

import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { formatGetCardParams } from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import { replace } from 'connected-react-router';
import TLSiConnector from '../TLSi/TLSiConnector';
import VitaleCardReaderProvider from '../Card/VitaleCardReaderProvider';
import {
  getAction, requestPcscReaders, selectCertifiedIdentity, setBackPage,
} from '../../dmpconnect/actions';
import { API_TYPES, searchPanels } from '../../dmpconnect/constants';
import VitaleCardPatients from '../VitaleCard/VitaleCardPatients';
import CheckAccessRights from '../AccessRights/CheckAccessRights';
import Loading from '../Common/Loading/Loading';
import { backPagesTypes } from '../../dmpconnect/helpers/nav';
import Alert from '../Common/Message/Alert';
import { isMssActive } from '../../dmpconnect/helpers';
import { getReaderName } from '../../dmpconnect/helpers/readers';

const AccessByVitaleCard = ({
  dispatch,
  apiType,
  esRestVitale,
  selectIns,
  accessRights,
  sessionSection,
  vitaleDataReady,
  vitaleDataError,
  vitaleCardReader,
  aldActive,
  insiActive,
  showUploadForm,
  selector,
  vitaleAutoRead,
  aldInfo,
  resetData,
  mssActive,
  vitaleCardReaderName,
}) => {
  React.useEffect(() => {
    if (!selector) {
      dispatch(setBackPage(backPagesTypes.DASHBOARD, `/home/${searchPanels.ACCESS_BY_VITALE_CARD_PANEL}`));
    }
  }, []);

  const resetVitaleData = () => {
    dispatch(getAction(
      commands.getVitaleCard,
      apiSections.VITALE_SECTION,
      formatGetCardParams(vitaleCardReader, vitaleCardReaderName),
      {},
    ));
    dispatch(clearSection(apiSections.VITALE_CARD_SECTION));
    dispatch(clearSection(apiSections.GET_APCV_CONTEXT));
    dispatch(clearSection(apiSections.GET_NFC_APCV_USER_DATA));
    dispatch(clearSection(apiSections.VITALE_DATA_SECTION));
    dispatch(clearSection(apiSections.INS_FROM_VITALE_CARD));
  };

  React.useEffect(() => {
    if (resetData) {
      resetVitaleData();
      dispatch(replace('/home/vitale'));
    }
  }, [resetData]);

  const selectPatient = (ins, certiIdSubSection) => {
    if (!selector) dispatch(selectCertifiedIdentity(certiIdSubSection));
    selectIns(ins);
  };

  const getReaders = () => {
    dispatch(clearSection(apiSections.PCSC_READERS_SECTION));
    dispatch(requestPcscReaders(false));
  };


  const content = (
    <div id="accessByCardPanel" className="my-patients-panel">
      {apiType === API_TYPES.REST && esRestVitale === true && !sessionSection.s_sessionId ? (
        <>
          {isLoading(sessionSection) && (
            <Loading message="Détection du connecteur ..." />
          )}
          {hasError(sessionSection) && (
            <Alert type="danger">
              Le connecteur DmpConnect-JS2 est nécessaire pour cette fonctionnalité.
              <div className="d-flex justify-content-end mt-1">
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => getReaders()}
                  // className="text-white"
                >
                  Relancer la détection
                </Button>
              </div>
            </Alert>
          )}
        </>
      ) : (
        <>
          {aldActive && aldInfo && (
            <Alert type="info">
              <strong>ALD : </strong>
              Il est nécessaire de lire la Carte Vitale du patient pour obtenir la liste de ses ALDs.
            </Alert>
          )}
          <VitaleCardReaderProvider monitor={vitaleAutoRead}>
            <VitaleCardPatients
              accessRights={accessRights}
              aldActive={aldActive}
              handleClick={(ins, certiIdSubSection) => selectPatient(ins, certiIdSubSection)}
              showUploadForm={showUploadForm}
              selector={selector}
              mssActive={mssActive}
            />
          </VitaleCardReaderProvider>
          <div className="d-flex justify-content-start">
            {(vitaleDataReady || vitaleDataError) && (
              <Button
                id="resetVitaleData"
                size="sm"
                onClick={() => resetVitaleData()}
              >
                Nouvelle lecture vitale
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );

  // if insi is active we need to create a tlsi connector
  if (insiActive || aldActive) {
    return <TLSiConnector showLoading>{content}</TLSiConnector>;
  }
  return content;
};

function mapStateToProps(state) {
  const insiConfig = getInsiConfig(state);
  const {
    dmpconnect: {
      [apiSections.VITALE_CARD_SECTION]: vitaleCard,
      [apiSections.VITALE_DATA_SECTION]: vitaleData,
      [apiSections.VITALE_SECTION]: vitale,
      [apiSections.SESSION_SECTION]: sessionSection,
    },
    dmpConnectPersistedAppConfiguration: {
      vitaleAutoRead,
    },
    dmpconnectPersistedConnectorConfiguration,
    dmpconnectConnectorConfig,
    dmpconnectApCvConfiguration,
  } = state;
  const cardLoading = isLoading(vitale) || isLoading(vitaleCard);
  const vitaleDataReady = vitaleData && isReady(vitaleData);
  const vitaleDataError = hasError(vitaleData);
  const vitaleCardReader = getConfigurationValue('vitaleCardReader', dmpconnectPersistedConnectorConfiguration);
  const esRestVitale = getConfigurationValue('esRestVitale', dmpconnectPersistedConnectorConfiguration);
  const apiType = getConfigurationValue('apiType', dmpconnectConnectorConfig);
  return {
    apCvActive: dmpconnectApCvConfiguration.active,
    insiActive: insiConfig.active,
    loading: cardLoading,
    vitaleReaderNotSet: Number(vitaleCardReader) === -1,
    vitaleCardReader,
    apiType,
    esRestVitale,
    sessionSection,
    vitaleDataReady,
    vitaleDataError,
    vitaleAutoRead,
    mssActive: isMssActive(state),
    vitaleCardReaderName: getReaderName(state, 'vitaleCardReader'),
  };
}

AccessByVitaleCard.defaultProps = {
  showUploadForm: null,
  aldActive: false,
  insiActive: false,
  vitaleDataReady: false,
  vitaleDataError: false,
  vitaleCardReader: -1,
  esRestVitale: false,
  sessionSection: null,
  selector: false,
  vitaleAutoRead: false,
  aldInfo: false,
  resetData: false,
  mssActive: false,
  vitaleCardReaderName: undefined,
};

AccessByVitaleCard.propTypes = {
  showUploadForm: PropTypes.func,
  selectIns: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  aldActive: PropTypes.bool,
  insiActive: PropTypes.bool,
  vitaleDataReady: PropTypes.bool,
  vitaleDataError: PropTypes.bool,
  vitaleCardReader: PropTypes.number,
  apiType: PropTypes.string.isRequired,
  esRestVitale: PropTypes.bool,
  accessRights: PropTypes.object.isRequired,
  sessionSection: PropTypes.object,
  selector: PropTypes.bool,
  vitaleAutoRead: PropTypes.bool,
  aldInfo: PropTypes.bool,
  resetData: PropTypes.bool,
  mssActive: PropTypes.bool,
  vitaleCardReaderName: PropTypes.string,
};

const connectedAccessByVitaleCard = CheckAccessRights(connect(mapStateToProps)(AccessByVitaleCard));

export default connectedAccessByVitaleCard;
