import * as yup from 'yup';
import { insTypes } from 'dmpconnectjsapp-base/constants';
import { mssSubTypes } from 'dmpconnectjsapp-base/actions/config/commands';
import { remoteDocumentFormats } from '../../constants/remoteConstants';
import { documentFormats, identifierTypes } from '../../constants';
import {
  informantAddressStreetNameType,
  informantAddressType,
  informantRelationType,
  informantTelecomType,
  informantType,
} from '../../constants/informants';
import '../../utils/formUtils';
import { remoteActions, remoteResponseChannels } from './index';
import { dmpSexes, insOidToType } from '../../constants/dmpConstants';

const command = {
  id: yup.string().required(),
  action: yup.string().oneOf(Object.values(remoteActions)).required(),
  confidentialityLevel: yup.string().oneOf(['true', 'false'], 'La valeur doit être true ou false'),
  responseChannel: yup.string().oneOf(Object.values(remoteResponseChannels)),
  responseChannelUrl: yup.string().url('L\'URL n\'est pas valide'),
};

const insiIdentity = {
  Ins: yup.object({
    startDate: yup.string().date().nullable(),
    endDate: yup.string().date().nullable(),
    value: yup.string().length(13, 'Le numéro INS doit contenir 13 caractères.').required('Le numéro INS est manquant'),
    key: yup.string().length(2, 'La clé du numéro INS doit contenir 2 caractères.').required('La clé du numéro INS est manquante'),
    oid: yup.string().oneOf(
      Object.keys(insOidToType),
      ({ values }) => `Le type d'INS doit être une des valeurs suivantes : ${values}`,
    ).required('Le type d\'INS est manquant'),
  }, 'La structure Ins est manquante.'),
  lastInsiCallDate: yup.string().date().nullable(),
  birthName: yup.string().required('Le nom de naissance est manquant'),
  given: yup.string().nullable(),
  birthGiven: yup.string().required('La liste des prénoms est manquante'),
  sex: yup.string().oneOf(
    Object.values(dmpSexes).map(sex => String(sex)),
    ({ values }) => `Le sexe doit être une des valeurs suivantes : ${values}`,
  ).required('le sexe est manquant'),
  birthDate: yup.string().date().required('La date de naissance est manquante'),
  birthPlace: yup.string().length(5, 'Le COG INSEE de naissance doit contenir 5 caractères').nullable(),
};

const patientIns = {
  ins: yup.object({
    root: yup.string().oneOf(
      Object.values(insTypes),
      ({ values }) => `Le type de l'ins doit être une des valeurs suivantes : ${values}`,
    ).required('Le type de l\'ins est manquant'),
    extension: yup.string()
      .min(15, 'l\'argument extension doit être un suite de 15 chiffres')
      .max(15, 'l\'argument extension doit être un suite de 15 chiffres')
      .required('L\'ins est manquant'),
  }).notRequired().default(undefined),
  Identity: yup.object().shape(insiIdentity).notRequired().default(undefined),
  exclusive: yup.bool().test(
    'exclusive',
    'Les balises ins et Identity sont exclusives, elles ne peuvent être passées en même temps',
    function () {
      return !(this.parent.ins && this.parent.Identity);
    },
  ),
  missing: yup.bool().test(
    'missing',
    'Au moins une des deux balises, ins ou Identity, doit être présente',
    function () {
      return this.parent.ins || this.parent.Identity;
    },
  ),
};

const findDmpFilters = {
  name: yup.object({
    family: yup.string().min(2, 'Le nom de famille doit contenir au moins 2 lettres.').nullable(),
    given: yup.string().nullable(),
    approximate: yup.bool('La valeur doit être true ou false').nullable(),
  }).nullable(),
  gender: yup.string().oneOf(['M', 'F', 'U', 'm', 'f', 'u'], 'La valeur doit être M (homme), F (femme) ou U (Inconnu)').nullable(),
  birthday: yup.string().date().nullable(),
  address: yup.object({
    postalCode: yup.string().nullable(),
    city: yup.string().nullable(),
    approximate: yup.bool('La valeur doit être true ou false').nullable(),
  }).nullable(),
};

const telecomsValidator = yup.array().of(yup.object().shape({
  type: yup.number()
    .oneOf(
      Object.values(informantTelecomType),
      'Le type d\'adresse est invalide',
    )
    .required('Le type d\'adresse est manquant.'),
  usage: yup.string(),
  value: yup.string().required('La valeur est manquante'),
}));

const adressesValidator = yup.array().of(yup.object().shape({
  type: yup.number()
    .oneOf(
      Object.values(informantAddressType),
      'Le type d\'adresse est invalide',
    )
    .required('Le type d\'adresse est manquant.'),
  country: yup.string().required('Le pays est manquant'),
  city: yup.string().required('La ville est manquante'),
  postalCode: yup.string().required('Le code postal est manquant'),
  houseNumber: yup.string(),
  houseNumberNumeric: yup.string(),
  streetNameType: yup.number()
    .oneOf(
      Object.values(informantAddressStreetNameType),
      'Le type de voie est invalide',
    )
    .required('Le type de voie est manquant.'),
  streetName: yup.string().required('Le nom de la voie est manquant'),
  additionalLocator: yup.string(),
  unitId: yup.string(),
  postBox: yup.string(),
  precInct: yup.string(),
}));

const hpStructureValidator = (professions, specialties, isIntendedRecipient = false) => {
  let profession = yup.string()
    .oneOf(
      professions.map(p => p.code),
      ({ values }) => `La profession doit être une des valeurs suivantes : ${values}`,
    );

  let professionOid = yup.string()
    .oneOf(
      professions.map(p => p.oid),
      'L\'identifiant de profession est invalide',
    );

  let specialty = yup.string()
    .oneOf(
      specialties.map(p => p.code),
      ({ values }) => `La spécialité doit être une des valeurs suivantes : ${values}`,
    );

  if (!isIntendedRecipient) {
    profession = profession.required('La profession est manquante.');
    professionOid = professionOid.required('L\'identifiant de profession est manquant.');
    specialty = specialty.when('profession', {
      is: p => [String(10), String(21)].includes(String(p)),
      then: schema => schema.required('La spécialité est manquante.'),
      otherwise: schema => schema.notRequired().default(undefined),
    });
  }

  return ({
    name: yup.string().required('Le nom du PS est manquant.'),
    given: yup.string().required('Le prénom du PS est manquant.'),
    profession,
    professionOid,
    specialty,
    internalId: yup.string().required('L\'identifiant du PS est manquant.'),
    internalIdType: yup.string().oneOf(Object.values(identifierTypes), 'Le type d\'identifiant est invalide'),
    authenticationMode: yup.string(),
    addresses: adressesValidator,
    telecoms: telecomsValidator,
  });
};

const eventCodeValidator = {
  code: yup.string().required('Le code est manquant'),
  classification: yup.string().required('La classification est manquante'),
  description: yup.string().required('La description est manquante'),
};

const informantValidator = {
  name: yup.string().required('Le nom est manquant'),
  given: yup.string().required('Le nom est manquant'),
  type: yup.number()
    .oneOf(
      Object.values(informantType),
      'Le type est invalide',
    )
    .required('Le type est manquant.'),
  relationType: yup.number()
    .oneOf(
      Object.values(informantRelationType),
      'Le type de relation est invalide',
    ),
  addresses: adressesValidator,
  telecoms: telecomsValidator,
};

const submitDocValidator = (categories, healthcareSettings, professions, specialties) => ({
  content: yup.string().required('Le contenu du document est manquant.'),
  stylesheet: yup.string(),
  format: yup.string()
    .oneOf(
      Object.keys(remoteDocumentFormats),
      ({ values }) => `Le format du document doit être un des formats suivants : ${values}`,
    )
    .required('Le format du document est manquant.'),
  title: yup.string().required('Le titre du document est manquant.'),
  typeCode: yup.string()
    .oneOf(
      categories.map(c => c.code),
      ({ values }) => `La catégorie du document doit être une des catégories suivantes : ${values}`,
    )
    .notOneOf(
      categories.filter(c => c.valid === false).map(c => c.code),
      'Cette catégorie de document n\'est pas utilisable pour déposer un document.',
    )
    .test(
      'typeCode',
      'La catégorie du document est obligatoire lors d\'un envoi en tache de fond',
      function (typeCode) {
        const { options: { context: { sendInBackground } = {} } = {} } = this;
        const isSendingInBackground = Number(sendInBackground) === 1 || sendInBackground === true;
        return !isSendingInBackground || (isSendingInBackground && !!typeCode);
      },
    ),
  creationDate: yup.string().date().pastDate(),
  serviceStartDate: yup.string().date().pastDate(),
  serviceStopDate: yup.string().date().pastDate(),
  role: yup.string(),
  practice: yup.string()
    .oneOf(
      healthcareSettings.map(c => c.code),
      ({ values }) => `Le cadre de soin doit être une des valeurs suivantes : ${values}`,
    )
    .test(
      'practice',
      'Le cadre de soin est obligatoire lors d\'un envoi en tache de fond',
      function (practice) {
        const { options: { context: { sendInBackground } = {} } = {} } = this;
        const isSendingInBackground = Number(sendInBackground) === 1 || sendInBackground === true;
        return !isSendingInBackground || (isSendingInBackground && !!practice);
      },
    ),
  performer: yup.object()
    .shape({ ...hpStructureValidator(professions, specialties) })
    .notRequired()
    .default(undefined),
  treatingPhysician: yup.object()
    .shape({ ...hpStructureValidator(professions, specialties) })
    .notRequired()
    .default(undefined),
  additionalAuthors: yup.array().of(
    yup.object().shape({ ...hpStructureValidator(professions, specialties) }),
  ),
  informants: yup.array().of(
    yup.object().shape(informantValidator),
  ),
  intendedRecipients: yup.array().of(
    yup.object().shape({ ...hpStructureValidator(professions, specialties, true) }),
  ),
  EventCodes: yup.array().of(
    yup.object().shape(eventCodeValidator),
  ),
  getDocumentContent: yup.bool(),
  retrieveDocumentUuid: yup.bool(),
  replacedDocumentUniqueId: yup.string(),
  versionNumber: yup.string(),
  setIdRoot: yup.string(),
  setIdExtension: yup.string(),
  ignorePdfA1Transparency: yup.bool(),
  disabledPdfA1Conversion: yup.bool(),
});

const sendMssMessageValidator = (categories, healthcareSettings, professions, specialties, mssApiType) => ({
  patient: yup.object({
    Identity: yup.object().shape(insiIdentity).notRequired().default(undefined),
  }).notRequired().default(undefined),
  sendInBackground: yup.string(),
  modal: yup.bool(),
  modalMessage: yup.string(),
  senderWording: yup.string(),
  replyTo: yup.string(),
  messageId: yup.string(),
  inReplyToMessageIds: yup.string(),
  references: yup.string(),
  recipient: yup.array().of(yup.string().email('L\'adresse email n\'est pas valide'))
    .required('La liste des destinataires est manquante'),
  cc: yup.array().of(yup.string().email('L\'adresse email n\'est pas valide')),
  bcc: yup.array().of(yup.string().email('L\'adresse email n\'est pas valide')),
  title: yup.string().test(
    'title',
    'Le sujet du message est obligatoire lors d\'un envoi en tache de fond',
    function (title) {
      const { sendInBackground } = this.parent;
      const isSendingInBackground = Number(sendInBackground) === 1 || sendInBackground === true;
      return !isSendingInBackground || (isSendingInBackground && !!title);
    },
  ),
  content: yup.string().test(
    'content',
    'Le contenu du message est obligatoire lors d\'un envoi en tache de fond',
    function (content) {
      const { sendInBackground } = this.parent;
      const isSendingInBackground = Number(sendInBackground) === 1 || sendInBackground === true;
      return !isSendingInBackground || (isSendingInBackground && !!content);
    },
  ),
  returnReceiptTo: yup.string().email('L\'adresse email n\'est pas valide')
    .test(
      'returnReceiptTo',
      'returnReceiptTo et dispositionNotificationTo ne peuvent être définis en même temps.',
      function (returnReceiptTo) {
        const { dispositionNotificationTo } = this.parent;
        return returnReceiptTo === undefined || dispositionNotificationTo === undefined;
      },
    ),
  dispositionNotificationTo: yup.string().email('L\'adresse email n\'est pas valide')
    .test(
      'dispositionNotificationTo',
      'returnReceiptTo et dispositionNotificationTo ne peuvent être définis en même temps.',
      function (dispositionNotificationTo) {
        const { returnReceiptTo } = this.parent;
        return dispositionNotificationTo === undefined || returnReceiptTo === undefined;
      },
    ),
  attachments: yup.array().of(yup.object({
    versionNumber: yup.string(),
    setIdRoot: yup.string(),
    setIdExtension: yup.string(),
    replacedDocumentUniqueId: yup.string(),
    // patientIns est requis si patient.Identity n'est pas fourni
    patientIns: yup.string().test('missing', 'L\'ins du patient est manquant', function (value) {
      const { patient: { Identity } = {} } = this.options.context;
      return value || Identity;
    }),
    stylesheet: yup.string(),
    fileContentInBase64: yup.string().required('Le contenu du document est manquant'),
    documentTitle: yup.string().required('Le titre du document est manquant'),
    documentDescription: yup.string(),
    documentCategory: yup.string()
      .oneOf(
        categories.map(c => c.code),
        ({ values }) => `La catégorie du document doit être une des catégories suivantes : ${values}`,
      )
      .required('La catégorie du document est manquante.'),
    documentFormat: yup.string()
      .oneOf(
        Object.values(documentFormats).map(format => String(format)),
        'Le format du document est invalide.',
      )
      .required('Le format du document est manquant.'),
    healthcareSetting: yup.string()
      .oneOf(
        healthcareSettings.map(c => c.code),
        ({ values }) => `Le cadre de soin doit être une des valeurs suivantes : ${values}`,
      )
      .required('Le cadre de soin est manquant.'),
    performer: yup.object()
      .shape({ ...hpStructureValidator(professions, specialties) })
      .notRequired()
      .default(undefined),
    treatingPhysician: yup.object()
      .shape({ ...hpStructureValidator(professions, specialties) })
      .notRequired()
      .default(undefined),
    additionalAuthors: yup.array().of(
      yup.object().shape({ ...hpStructureValidator(professions, specialties) }),
    ),
    informants: yup.array().of(yup.object().shape(informantValidator)),
    intendedRecipients: yup.array().of(
      yup.object().shape({ ...hpStructureValidator(professions, specialties, true) }),
    ),
    EventCodes: yup.array().of(yup.object().shape(eventCodeValidator)),
  })),
  rawAttachments: yup.array().of(yup.object({
    documentTitle: yup.string().required('Le title du document est manquant.'),
    fileContentInBase64: yup.string().required('Le contenu du document est manquant.'),
    contentType: yup.string().required('Le type mime du document est manquant.')
      .test(
        'contentType',
        'Le type mime du document ne peut dépasser 40 caractères.',
        contentType => !(mssApiType === mssSubTypes.WEB && contentType.length > 40),
      ),
  })),
  insIsNotQualified: yup.bool(),
  AdditionalPatientIdentifiers: yup.array().of(yup.object().shape({
    patientIdentifierRootOid: yup.string().required('La racine de l\'identifiant est manquante'),
    patientIdentifier: yup.string(),
  })).when('insIsNotQualified', {
    is: true,
    then: schema => schema.required('Au moins une identité additionnelle est nécessaire si l\'INS n\'est pas qualifié'),
    otherwise: schema => schema.notRequired().default(undefined),
  }),
  ignorePdfA1Transparency: yup.bool(),
  disabledPdfA1Conversion: yup.bool(),
  disableIheXdmPdfDataMatrixBlock: yup.bool(),
  disableIheXdmPdfTitlePage: yup.bool(),
  getDocumentContent: yup.bool(),
  forbidRecipientAnswer: yup.bool(),
});

const deleteDocumentValidator = healthcareSettings => ({
  patient: yup.object().shape({ ...patientIns }),
  submitEngine: yup.string(),
  document: yup.object({
    uniqueId: yup.string().required('L\'identifiant unique (uniqueId) du document est manquant'),
    uuid: yup.string(),
    practice: yup.string()
      .oneOf(
        healthcareSettings.map(c => c.code),
        ({ values }) => `Le cadre de soin doit être une des valeurs suivantes : ${values}`,
      )
      .required('Le cadre de soin est manquant.'),
  }, 'Les informations du document à supprimer sont manquantes'),
});

const advancedQueryValidator = types => ({
  type: yup.string()
    .oneOf(types, ({ values }) => `Le type de recherche doit être une des valeurs suivantes : ${values}.`)
    .required('Le type de recherche est manquant.'),
  attributeName: yup.string().when('type', {
    is: type => type && type === 'EQUAL',
    then: schema => schema.required('Le nom de l\'attribut sur lequel effectuer la recherche est manquant.'),
    otherwise: schema => schema,
  }),
  attributeValue: yup.string().when('type', {
    is: type => type && type === 'EQUAL',
    then: schema => schema.required('La valeur à rechercher est manquante.'),
    otherwise: schema => schema,
  }),
});

const getMssHpInfosValidator = {
  name: yup.string(),
  given: yup.string(),
  rpps: yup.string(),
  specialty: yup.string(),
  organization: yup.string(),
  query: yup.object().shape({
    ...advancedQueryValidator(['EQUAL', 'AND', 'OR', 'NOT']),
    operand1: yup.object().when('type', {
      is: type => type && ['AND', 'OR', 'NOT'].includes(type || ''),
      then: schema => schema.shape(advancedQueryValidator(['EQUAL']))
        .default(undefined)
        .required('La première partie de la recherche est manquante.'),
      otherwise: schema => schema,
    }),
    operand2: yup.object().when('type', {
      is: type => type && ['AND', 'OR', 'NOT'].includes(type || ''),
      then: schema => schema.shape(advancedQueryValidator(['EQUAL']))
        .default(undefined)
        .required('La deuxième partie de la recherche est manquante.'),
      otherwise: schema => schema,
    }),
  }).default(undefined).notRequired(),
};

const generateValidators = (categories, healthcareSettings, professions, specialties, mssApiType) => ({
  [remoteActions.logout]: yup.object().shape(command),
  [remoteActions.sendCpxPinCode]: yup.object().shape({
    ...command,
    pinCode: yup.string().required('Le code porteur est manquant.'),
  }),
  [remoteActions.setPscAccessToken]: yup.object().shape({
    ...command,
    accessToken: yup.string().required('Le token est manquant.'),
  }),
  [remoteActions.getCurrentDmp]: yup.object().shape(command),
  [remoteActions.getStatus]: yup.object().shape(command),
  [remoteActions.getEfficienceVersion]: yup.object().shape(command),
  [remoteActions.closeDmpSession]: yup.object().shape(command),
  [remoteActions.testDmpExistence]: yup.object().shape({
    ...command,
    patient: yup.object().shape({ ...patientIns }),
  }),
  [remoteActions.openDmp]: yup.object().shape({
    ...command,
    patient: yup.object().shape({ ...patientIns }),
  }),
  [remoteActions.findDmp]: yup.object().shape({
    ...command,
    filters: yup.object().shape(findDmpFilters),
  }),
  [remoteActions.getCertifiedIdentity]: yup.object().shape({
    ...command,
    patient: yup.object({
      nir: yup.object({
        root: yup.string().oneOf(
          Object.values(insTypes),
          ({ values }) => `Le type du nir doit être une des valeurs suivantes : ${values}`,
        ).required('Le type du nir est manquant'),
        extension: yup.string()
          .min(15, 'l\'argument extension doit être un suite de 15 chiffres')
          .max(15, 'l\'argument extension doit être un suite de 15 chiffres')
          .required('Le nir est manquant'),
      }, 'Le nir est manquant'),
      birthDate: yup.string().date().required('La date de naissance est manquante'),
      birthRank: yup.number().required('Le rang de naissance est manquant'),
    }),
  }),
  [remoteActions.submitDocument]: yup.object().shape({
    ...command,
    patient: yup.object().shape({ ...patientIns }),
    sendInBackground: yup.bool(),
    modal: yup.bool(),
    modalMessage: yup.string(),
    document: yup.object().shape({
      ...submitDocValidator(categories, healthcareSettings, professions, specialties),
    }),
    submitEngine: yup.string(),
    AdditionalPatientIdentifiers: yup.array().of(yup.object().shape({
      patientIdentifierRootOid: yup.string().required('La racine de l\'identifiant est manquante'),
      patientIdentifier: yup.string(),
    })),
  }),
  [remoteActions.deleteDocument]: yup.object().shape({
    ...command,
    ...deleteDocumentValidator(healthcareSettings),
  }),
  [remoteActions.sendMssMessage]: yup.object().shape({
    ...command,
    ...sendMssMessageValidator(categories, healthcareSettings, professions, specialties, mssApiType),
  }),
  [remoteActions.getMssNbUnreadMessages]: yup.object().shape({
    ...command,
    folderId: yup.string(),
  }),
  [remoteActions.getMssHpInfos]: yup.object().shape({
    ...command,
    ...getMssHpInfosValidator,
  }).test(
    'no-inputs',
    'Aucun critère de recherche fourni',
    (request) => {
      const {
        name, given, rpps, specialty, organization, query,
      } = request;
      return name || given || rpps || specialty || organization || query;
    },
  ).test(
    'both-search-types',
    'Les deux types de recherche sont définis : basique (name, given, rpps, specialty, organization) et avancée (query). Utilisez l\'un ou l\'autre.',
    (request) => {
      const {
        name, given, rpps, specialty, organization, query,
      } = request;
      return !((name || given || rpps || specialty || organization) && query);
    },
  ),
});

export const validateJSONCommand = (request, categories, healthcareSettings, professions, specialties, mssApiType) => {
  yup.object().shape(command).validateSync(request, { abortEarly: false });
  const { action } = request;
  const validators = generateValidators(categories, healthcareSettings, professions, specialties, mssApiType);
  const validator = validators[action];
  if (validator) {
    validator.validateSync(request, { abortEarly: false, context: request });
  }
};
