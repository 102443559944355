import React, { Component } from 'react';
import merge from 'lodash.merge';
import { push } from 'connected-react-router';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Platform from 'react-platform-js';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { clearSection } from 'dmpconnectjsapp-base/actions';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { formatGetDocumentContentByUniqueIdParams } from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import { getHealthcareSetting } from 'dmpconnectjsapp-base/helpers/accessors';
import {
  getError, hasError, isLoading, isReady,
} from 'dmpconnectjsapp-base/helpers/common';
import {
  Form, Modal, ToggleButton, ToggleButtonGroup,
} from 'react-bootstrap';
import {
  documentVisibilityStatuses,
  findDocumentParamsDefault,
  isHiddenFor,
} from 'dmpconnectjsapp-base/helpers/findDocuments';
import moment from 'moment';
import { documentFormatNames, documentFormats } from '../../dmpconnect/constants';
import ButtonWithLoader from '../Common/Form/ButtonWithLoader';
import {
  getAction,
  resetMssEmailContent, setMobilePreviewActive,
  setMssEmailContent,
  setShowMssPopup,
  showUploadDocumentModal,
} from '../../dmpconnect/actions';
import { b64DecodeUnicode, getDocumentFormatByExtension } from '../../dmpconnect/utils/dataUtils';
import DMPDocumentPreview from '../DMPViewer/DMPDocumentPreview';
import DMPDocumentUploadForm from './Form/DMPDocumentUploadForm';
import Popable from '../Common/Modal/Popable';
import { formatUploadDocumentParams, initialValues, uploadType } from '../../dmpconnect/helpers/sendDocument';
import SectionStatusWrapper from '../Common/SectionStatus/SectionStatusWrapper';
import Loading from '../Common/Loading/Loading';
import { getDocumentData } from '../../dmpconnect/helpers/findDocuments';
import ConnectedRemoteDMPSelector from '../Remote/RemoteDMPSelector';
import ConnectedVirtualPrinterDMPSelector from '../VirtualPrinter/VIrtualPrinterDMPSelector';
import TitleTooltip from '../Common/TitleTooltip';
import Alert from '../Common/Message/Alert';
import { getUniqueUUid } from '../../dmpconnect/rules/documentRules';
import { getCurrentFilter } from '../../dmpconnect/helpers/searchUtils';
import { getUserConfigFromState } from '../../dmpconnect/helpers/user';
import { isMssActive } from '../../dmpconnect/helpers';
import { getMobileLandingUrl } from '../../dmpconnect/helpers/nav';

const getFileTypeIcon = (format) => {
  if (format === documentFormats.pdf) return 'ic-filetype-pdf';
  if (format === documentFormats.jpeg) return 'ic-filetype-jpg';
  return 'ic-filetype';
};
export const dmpSelectors = {
  REMOTE: 'remote',
  VIRTUAL_PRINTER: 'virtualPrinter',
};

class DMPDocumentUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      uploadingFile: false,
      showPreview: props.showPreview,
      showPreviewFullWidth: false,
      waitForReplaceDocData: false,
      errorFileFormat: false,
      errorFileSize: false,
      zoomLevel: 0,
    };
    this.form = React.createRef();
    this.onDrop = this.onDrop.bind(this);
    this.togglePreview = this.togglePreview.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      ins: prevIns,
      show: prevShow,
      mobilePreviewActive: prevMobilePreviewActive,
      ...prevPropsRest
    } = prevProps;

    const {
      ins,
      setFieldValue,
      dmpSelector,
      show,
      mobilePreviewActive,
      ...propsRest
    } = this.props;

    if (!prevShow && show) {
      this.setShow(true);
    }
    if (prevMobilePreviewActive !== mobilePreviewActive) {
      this.setPreview(mobilePreviewActive);
    }

    const {
      values: {
        base64, replaceDocumentId = null, replaceDocumentUuid = null, contribution,
      },
      documentContentSection: {
        [getUniqueUUid(replaceDocumentId, replaceDocumentUuid)]: replaceDocumentContent = {},
      },
    } = propsRest;

    const {
      documentContentSection: {
        [getUniqueUUid(replaceDocumentId, replaceDocumentUuid)]: prevReplaceDocumentContent = {},
      },
    } = prevPropsRest;

    if (base64 === '' && contribution === '' && !isReady(prevReplaceDocumentContent) && isReady(replaceDocumentContent)) {
      const { documentToReplace: { i_document_Format } } = this.state;
      this.setReplaceDocumentContentData(replaceDocumentContent, i_document_Format, setFieldValue);
    }
  }

  componentWillUnmount() {
    this.handleClose(false);
  }

  onDrop(acceptedFiles) {
    const { setFieldValue, currentCategories } = this.props;

    this.clearFile();
    this.setState({
      uploadingFile: true, show: true, errorFileFormat: false, errorFileSize: false,
    });

    const reader = new FileReader();
    const file = acceptedFiles[0];

    const fileFormats = getDocumentFormatByExtension(file.name);
    if (!file || fileFormats.length === 0) {
      this.setState({ uploadingFile: false, errorFileFormat: true });
      return;
    }

    if (file.size > 5000000) {
      this.setState({ uploadingFile: false, errorFileSize: true });
      return;
    }

    if (currentCategories.length === 1) {
      setFieldValue('typeCode', currentCategories[0]);
    }

    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const dataUri = event.target.result;

      const base64 = dataUri
        .substring(dataUri.lastIndexOf('base64,'))
        .replace('base64,', '');

      setFieldValue('base64', base64);
      setFieldValue('filename', file.name);

      if (fileFormats.length > 1) {
        setFieldValue('formats', fileFormats);
        setFieldValue('documentFormat', -1);
      } else {
        setFieldValue('formats', []);
        setFieldValue('documentFormat', parseInt(fileFormats[0][0], 10));
      }

      this.setState({ uploadingFile: false });
    };
  }

  setShow(show) {
    this.setState({ show });
  }

  setPreview(preview) {
    this.setState(prevState => ({
      showPreview: preview,
      showPreviewFullWidth: preview,
      zoomLevel: !preview ? 0 : prevState.zoomLevel,
    }));
  }

  setReplaceDocumentContentData(replaceDocumentContent, documentFormat, setFieldValue) {
    const documentData = getDocumentData(replaceDocumentContent);
    if (documentFormat === documentFormats.textPlain) {
      setFieldValue('contribution', b64DecodeUnicode(documentData));
      setFieldValue('base64', '');
    } else {
      setFieldValue('base64', documentData);
      setFieldValue('contribution', '');
    }
  }

  handleClose(doOnClose = true) {
    const { dispatch, onClose } = this.props;
    const { showPreviewFullWidth } = this.state;
    if (showPreviewFullWidth) {
      this.setState({ showPreviewFullWidth: false, showPreview: true });
    } else {
      dispatch(showUploadDocumentModal(false, null));
      this.setState({ show: false });
      this.handleNewDoc();
      if (onClose && doOnClose === true) {
        onClose();
      }
    }
  }

  handleShow(clearFile = false, documentToReplace = null, loadDocumentToReplaceData = true) {
    const {
      currentCategories,
      interopCategories,
      dispatch,
      documentContentSection,
      setFieldValue,
      ins,
      healthcareSetting,
      uploadDocTitlePrefix,
    } = this.props;

    if (clearFile) {
      this.clearFile();
    }

    dispatch(clearSection(apiSections.SEND_DOCUMENT_SECTION));

    this.setState({ waitForReplaceDocData: loadDocumentToReplaceData });

    if (documentToReplace) {
      setFieldValue(
        'type',
        documentToReplace.i_document_Format === documentFormats.textPlain
          ? uploadType.plainTextUpload
          : uploadType.fileUpload,
      );
      setFieldValue('replaceDocumentId', documentToReplace.s_uniqueId);
      setFieldValue('replaceDocumentUuid', documentToReplace.s_uuid);
      setFieldValue('typeCode', documentToReplace.s_typeCode || documentToReplace.s_documentCategory);
      setFieldValue('title', documentToReplace.s_title || documentToReplace.s_documentTitle);
      setFieldValue('description', documentToReplace.s_description || documentToReplace.s_documentDescription || '');
      setFieldValue('documentFormat', documentToReplace.i_document_Format || documentToReplace.i_documentFormat);
      setFieldValue('creationDate', moment(documentToReplace.s_creationDate, 'YYYYMMDDHHmmss').format('DD/MM/YYYY'));
      setFieldValue('serviceStartDate', moment(documentToReplace.s_serviceStartDate, 'YYYYMMDDHHmmss').format('DD/MM/YYYY'));
      setFieldValue('serviceStopDate', moment(documentToReplace.s_serviceStopDate, 'YYYYMMDDHHmmss').format('DD/MM/YYYY'));
      setFieldValue('patientHidden', isHiddenFor(
        documentToReplace.i_document_Visibility,
        documentVisibilityStatuses.PATIENT_HIDDEN,
      ));
      setFieldValue('healthcareProfessionalHidden', isHiddenFor(
        documentToReplace.i_document_Visibility,
        documentVisibilityStatuses.HEALTCARE_PROFESSIONAL_HIDDEN,
      ));
      setFieldValue('guardianHidden', isHiddenFor(
        documentToReplace.i_document_Visibility,
        documentVisibilityStatuses.GUARDIAN_HIDDEN,
      ));

      const { [getUniqueUUid(documentToReplace.s_uniqueId, documentToReplace.s_uuid)]: replaceDocumentContent } = documentContentSection;
      if (isReady(replaceDocumentContent)) {
        this.setReplaceDocumentContentData(replaceDocumentContent,
          documentToReplace.i_document_Format,
          setFieldValue);
      } else if (!hasError(replaceDocumentContent) && !isLoading(replaceDocumentContent) && loadDocumentToReplaceData === true) {
        dispatch(getAction(
          commands.getDocumentContentByUniqueId,
          apiSections.DOCUMENT_CONTENT_SECTION,
          formatGetDocumentContentByUniqueIdParams(ins, documentToReplace.s_uniqueId, documentToReplace.s_uuid),
          {
            subSection: getUniqueUUid(documentToReplace.s_uniqueId, documentToReplace.s_uuid),
          },
        ));
      }
    } else {
      setFieldValue('type', uploadType.fileUpload);
      setFieldValue('replaceDocumentId', null);
      setFieldValue('replaceDocumentUuid', null);

      if (currentCategories.length === 1) {
        const cat = interopCategories.find(c => c.code === currentCategories[0]);
        if (cat && cat.valid === true) {
          setFieldValue('typeCode', currentCategories[0]);
        }
      }

      setFieldValue('title', uploadDocTitlePrefix || '');
      setFieldValue('description', '');
    }

    setFieldValue('healthcareSetting', healthcareSetting);
    this.setState({
      show: true,
      documentToReplace,
    });
  }

  clearFile() {
    const { setFieldValue } = this.props;
    setFieldValue('filename', '');
    setFieldValue('base64', '');
    setFieldValue('contribution', '');
    setFieldValue('documentFormat', -1);
    setFieldValue('formats', []);
    setFieldValue('type', uploadType.fileUpload);
  }

  togglePreview(showPreview = null, fullWitdh = false) {
    const { isMobile, dispatch } = this.props;

    if (isMobile) {
      dispatch(setMobilePreviewActive(showPreview));
      this.setState(prevState => ({ zoomLevel: !showPreview ? 0 : prevState.zoomLevel }));
    } else {
      this.setState(prevState => ({
        showPreview: showPreview !== null ? showPreview : !prevState.showPreview,
        showPreviewFullWidth: fullWitdh,
      }));
    }
  }

  handleNewDoc() {
    const {
      dispatch, resetForm, formInitialValues, certifiedIdentity, healthcareSetting,
    } = this.props;
    const newValues = merge({}, formInitialValues, {
      healthcareSetting,
      certifiedIdentity,
    });
    resetForm(newValues);
    dispatch(clearSection(apiSections.SEND_DOCUMENT_SECTION));
  }

  handleSetMssContent() {
    // eslint-disable-next-line react/prop-types
    const { dispatch, values, ins } = this.props;
    const {
      typeCode,
      data,
      title,
      description,
      format,
      healthcareSetting,
    } = formatUploadDocumentParams(values);

    dispatch(resetMssEmailContent(true));
    dispatch(setMssEmailContent({
      attachments: [{
        patientIns: ins,
        fileContentInBase64: data,
        documentTitle: title,
        documentDescription: description,
        documentCategory: typeCode,
        documentFormat: format,
        healthcareSetting,
      }],
    }));
    dispatch(setShowMssPopup(true));
  }

  render() {
    const {
      show,
      documentToReplace,
      showPreview,
      showPreviewFullWidth,
      uploadingFile,
      waitForReplaceDocData,
      errorFileFormat,
      errorFileSize,
      zoomLevel,
    } = this.state;

    const {
      sendDocumentSection,
      asPopup,
      formInitialValues,
      onlyForm,
      cpxCard,
      dmpconnectCPxConfiguration,
      healthcareSettings,
      healthcareSetting,
      categories,
      dmpSelector,
      ins,
      directAuthenticationDMPStatus,
      documentContentSection,
      noFileType,
      resetIns,
      onResetIns,
      allowDeleteFile,
      titleRoot,
      fullTitle,
      showBackButton,
      onBack,
      backLabel,
      backDisabled,
      mssActive,
      isMobile,
      dispatch,
      cancelButtonLabel,
      cancelButtonTooltip,
      submitButtonLabel,
      ...formikProps
    } = this.props;

    const {
      isValid,
      handleSubmit,
      setFieldValue,
      values: {
        base64 = '',
        // stylesheetApplied,
        structuredHtml,
        structuredHeader,
        documentFormat,
        type,
        replaceDocumentId,
        replaceDocumentUuid,
        contribution,
        filename,
      },
    } = formikProps;
    const stylesheetApplied = true;

    const { [getUniqueUUid(replaceDocumentId, replaceDocumentUuid)]: replacedDocumentContent } = documentContentSection;

    merge(formInitialValues, { healthcareSetting });

    const form = (
      <DMPDocumentUploadForm
        onPreviewClick={this.togglePreview}
        onDrop={acceptedFiles => this.onDrop(acceptedFiles, null)}
        healthcareSettings={healthcareSettings}
        categories={categories}
        uploadingFile={uploadingFile}
        noFileType={noFileType}
        resetIns={resetIns}
        onResetIns={onResetIns}
        isMobile={isMobile}
        {...formikProps}
      />
    );

    const {
      ExistingTestAnswer: {
        AdminData: {
          s_patientGivenName: givenName,
          s_patientFirstBirthGiven: firstGivenName,
          s_patientName: name,
          s_patientBirthName: birthName,
        } = {},
      } = {},
    } = directAuthenticationDMPStatus;
    const uploadLabel = fullTitle || `${titleRoot || ''}Déposer un document dans le DMP de ${name || birthName || ''} ${firstGivenName || givenName || ''}`;
    const success = isReady(sendDocumentSection);
    const error = getError(sendDocumentSection);
    const loading = isLoading(sendDocumentSection);
    return (
      <>
        {!onlyForm && (
          <div onClick={() => this.handleShow(true)}>
            <Dropzone
              noClick
              onDrop={acceptedFiles => this.onDrop(acceptedFiles)}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="drop-zone">
                  <div {...getRootProps()} className="centered-container p-2">
                    <input {...getInputProps()} />
                    <span className="icon">
                      <i className="ic-upload-document" />
                    </span>
                    <div className="mt-2">
                      <span>Déposez un fichier ou cliquez pour alimenter le DMP</span>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        )}
        <Popable
          keyboard={false}
          backdrop="static"
          dialogClassName={`modal-upload-doc ${showPreviewFullWidth === true ? 'full-width-preview' : ''}`}
          popup={asPopup}
          shown={show}
          title={documentToReplace ? 'Modification' : uploadLabel}
          footerClassName={showPreviewFullWidth === true ? 'justify-content-end' : 'justify-content-between'}
          footer={(
            <>
              {showPreviewFullWidth === true ? (
                <Button
                  variant="outline-secondary"
                  onClick={() => this.togglePreview(false)}
                >
                  Fermer la prévisualisation
                </Button>
              ) : (
                <>
                  <TitleTooltip
                    id="upload-cancel-button"
                    text={cancelButtonTooltip}
                    showTooltip={!!cancelButtonTooltip}
                  >
                    <Button
                      variant="outline-secondary"
                      onClick={() => this.handleClose()}
                      disabled={loading}
                    >
                      {success ? 'Fermer' : cancelButtonLabel}
                    </Button>
                  </TitleTooltip>
                  {showBackButton === true && (
                    <Button
                      variant="outline-secondary"
                      className="mr-auto"
                      onClick={onBack}
                      disabled={backDisabled}
                    >
                      {backLabel}
                    </Button>
                  )}
                  {!success && (
                    <ButtonWithLoader
                      disabled={!isValid}
                      label={submitButtonLabel}
                      onClick={handleSubmit}
                      loading={loading}
                    />
                  )}
                  {success && (
                    <div>
                      {mssActive && (
                      <Button
                        variant="outline-primary"
                        className="mr-2"
                        onClick={() => this.handleSetMssContent()}
                      >
                        Envoyer via MSS
                      </Button>
                      )}
                      <Button
                        variant="primary"
                        onClick={() => this.handleNewDoc()}
                      >
                        Déposer un nouveau document
                      </Button>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          size="lg"
          onHide={() => this.handleClose(false)}
          handleClose={() => this.handleClose()}
        >
          <SectionStatusWrapper
            loading={
              (ins && ins !== -1 && !isReady(directAuthenticationDMPStatus))
              || (waitForReplaceDocData && replaceDocumentId && !isReady(replacedDocumentContent) && !!hasError(replacedDocumentContent))}
            loadingContent={<Loading />}
            error={error}
            forceErrorMessageIfModal
            showChildrenOnError
            success={success}
            successContent={(
              <>
                {isMobile ? (
                  <Modal
                    size="lg"
                    centered
                    backdrop="static"
                    keyboard={false}
                    show
                    onHide={() => dispatch(push(getMobileLandingUrl(ins)))}
                    className="modal-dropdown"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title as="h5">Déposer un document</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="d-flex">
                      <div className="status">
                        <div className="status-icon">
                          <i className="ic-big-check" />
                        </div>
                        <div className="status-text">Document déposé avec succès</div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer className="no-shadow">
                      <Button
                        size="sm"
                        variant="borderless-primary"
                        onClick={() => dispatch(push(getMobileLandingUrl(ins)))}
                      >
                        Fermer
                      </Button>
                      <Button
                        variant="outline-primary"
                        className="bg-transparent"
                        onClick={() => this.handleNewDoc()}
                      >
                        Déposer un nouveau document
                      </Button>
                    </Modal.Footer>
                  </Modal>
                ) : (
                  <Alert type="success">Le document a été déposé avec succès.</Alert>
                )}
              </>
            )}
          >
            <div className="upload-document">
              {ins ? (
                <>
                  <div className={showPreviewFullWidth === true ? 'full-side' : 'left-side'}>
                    {isMobile && !showPreview && (
                    <Form.Group controlId="Type" className="document-type-select">
                      <ToggleButtonGroup
                        type="radio"
                        name="type"
                        onChange={value => setFieldValue('type', value)}
                        value={type}
                      >
                        <ToggleButton
                          size="sm"
                          className="mb-0 toggle-button"
                          variant="outline-secondary"
                          value={uploadType.fileUpload}
                          onClick={() => {
                            setFieldValue('contribution', '');
                          }}
                        >
                          <i className={isMobile ? 'ic-file-plus' : 'ic-document-file-small'} />
                          Fichier
                        </ToggleButton>
                        <ToggleButton
                          size="sm"
                          className="mb-0 toggle-button"
                          variant="outline-secondary"
                          value={uploadType.plainTextUpload}
                          onClick={() => {
                            setFieldValue('filename', '');
                            setFieldValue('base64', '');
                            setFieldValue('documentFormat', -1);
                            setFieldValue('formats', []);
                          }}
                        >
                          <i
                            className={isMobile ? 'ic-file-text' : 'ic-document-text-small'}
                            style={isMobile ? { fontSize: '0.8rem' } : undefined}
                          />
                          Texte
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Form.Group>
                    )}
                    {type === uploadType.fileUpload ? (
                      <>
                        {!isMobile ? (
                          <div className={`document ${showPreview ? 'justify-content-center' : ''} ${stylesheetApplied ? 'flex-grow-1' : ''}`}>
                            {base64 !== '' && Platform.Browser !== 'IE' ? (
                              <>
                                {showPreview ? (
                                  <DMPDocumentPreview
                                    stylesheetApplied={stylesheetApplied}
                                    content={structuredHtml || base64}
                                    headerCda={structuredHeader}
                                    className={`preview ${stylesheetApplied ? 'flex-grow-1' : ''} ${showPreviewFullWidth === true ? 'preview-container-fw' : 'preview-container-lg'}`}
                                    information={{ i_document_Format: documentFormat }}
                                    pager
                                    buttons={(
                                      <>
                                        <TitleTooltip
                                          id="preview-close-button"
                                          text="Fermer la prévisualisation"
                                        >
                                          <Button
                                            variant="outline-secondary"
                                            size="sm"
                                            className="close-button btn-icon-only"
                                            onClick={() => this.togglePreview(false)}
                                          >
                                            <i className="ic-close-fullscreen-document" />
                                          </Button>
                                        </TitleTooltip>

                                        <TitleTooltip
                                          id="preview-zoom-button"
                                          text="Afficher la prévisualisation en plein écran"
                                        >
                                          <Button
                                            size="sm"
                                            variant="outline-secondary"
                                            className="btn-icon-only zoom-button"
                                            onClick={() => this.togglePreview(true, true)}
                                          >
                                            <i className="ic-fullscreen" />
                                          </Button>
                                        </TitleTooltip>
                                      </>
                                    )}
                                  />
                                ) : (
                                  <div className="details">
                                    <div className="preview">
                                      <DMPDocumentPreview
                                        stylesheetApplied={stylesheetApplied}
                                        content={structuredHtml || base64}
                                        information={{ i_document_Format: documentFormat }}
                                        buttons={(
                                          <TitleTooltip
                                            id="preview-zoom-button"
                                            text="Afficher la prévisualisation"
                                          >
                                            <Button
                                              size="sm"
                                              variant="outline-secondary"
                                              className="btn-icon-only zoom-button"
                                              onClick={() => this.togglePreview(true)}
                                            >
                                              <i className="ic-fullscreen" />
                                            </Button>
                                          </TitleTooltip>
                                        )}
                                      />
                                    </div>
                                    <div className="infos">
                                      {allowDeleteFile && (
                                      <TitleTooltip
                                        id="preview-delete-button"
                                        text="Supprimer le document"
                                      >
                                        <Button
                                          variant="outline-danger"
                                          className="ml-auto btn-icon-only"
                                          onClick={() => this.clearFile()}
                                        >
                                          <i className="ic-delete text-danger" />
                                        </Button>
                                      </TitleTooltip>
                                      )}
                                      <div className="typography-default-text-title mt-3">
                                        {filename}
                                      </div>
                                      <div className="typography-default-text text-gray-400 mt-3">
                                        Format :
                                        {' '}
                                        {documentFormatNames[documentFormat]}
                                      </div>
                                      <div className="typography-default-text text-gray-400 mt-3">
                                        Veuillez renseigner les informations du document
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <Dropzone
                                  noClick
                                  onDrop={acceptedFiles => this.onDrop(acceptedFiles)}
                                >
                                  {({
                                    getRootProps, getInputProps, open, isDragActive,
                                  }) => (
                                    <div className="drop-zone">
                                      <div {...getRootProps()} className="centered-container">
                                        <input {...getInputProps()} />
                                        {isDragActive ? (
                                          <>
                                            <div className="drop-file" />
                                            <div className="mt-2">
                                              <span>Déposer le fichier ici.</span>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              type="button"
                                              onClick={open}
                                              className="btn btn-primary btn-sm"
                                            >
                                              Choisir un fichier
                                            </button>
                                            <div className="mt-2">
                                              <span>ou glisser un fichier ici.</span>
                                            </div>
                                            {errorFileFormat && (
                                            <Alert type="danger" className="mt-3">
                                              Format de fichier non supporté.
                                            </Alert>
                                            )}
                                            {errorFileSize && (
                                            <Alert type="danger" className="mt-3">
                                              Fichier trop volumineux.
                                              <br />
                                              {' '}
                                              Le DMP n&apos;accepte que des fichiers de taille inférieure à 5 Mo environ.
                                            </Alert>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                              </>
                            )}
                          </div>
                        ) : (
                          <>
                            {base64 === '' ? (
                              <div className="file-inputs">
                                <Dropzone
                                  noDrag
                                  onDrop={acceptedFiles => this.onDrop(acceptedFiles)}
                                >
                                  {({ getRootProps, getInputProps, isDragActive }) => (
                                    <div className="file file-input" {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <i className="ic-upload-file ic-bg-primary" />
                                      <span>Choisir un fichier</span>
                                    </div>
                                  )}
                                </Dropzone>
                                <Dropzone
                                  noDrag
                                  onDrop={acceptedFiles => this.onDrop(acceptedFiles)}
                                >
                                  {({ getRootProps, getInputProps, isDragActive }) => (
                                    <div className="capture file-input" {...getRootProps()}>
                                      <input {...getInputProps()} accept="image/*" capture />
                                      <i className="ic-upload-photo ic-bg-primary" />
                                      <span>Prendre une photo</span>
                                    </div>
                                  )}
                                </Dropzone>
                              </div>
                            ) : (
                              <>
                                {showPreview ? (
                                  <DMPDocumentPreview
                                    stylesheetApplied={stylesheetApplied}
                                    content={structuredHtml || base64}
                                    information={{ i_document_Format: documentFormat }}
                                    className={`preview zoom-${zoomLevel} ${stylesheetApplied ? 'flex-grow-1' : ''} preview-container-fw`}
                                    pager
                                    buttons={(
                                      <div className="preview-buttons">
                                        <Button
                                          onClick={() => this.togglePreview(false, false)}
                                        >
                                          <i className="ic-minimize ic-bg-primary" />
                                        </Button>
                                        <Button
                                          variant="outline-danger"
                                          onClick={() => {
                                            setFieldValue('filename', '');
                                            setFieldValue('base64', '');
                                            setFieldValue('documentFormat', -1);
                                            setFieldValue('formats', []);
                                            this.togglePreview(false, false);
                                          }}
                                        >
                                          <i className="ic-delete text-danger" />
                                        </Button>
                                        <div className="zoom">
                                          <Button
                                            onClick={() => this.setState({ zoomLevel: zoomLevel - 1 })}
                                            disabled={zoomLevel === 0}
                                          >
                                            <i className="ic-minus ic-bg-primary" />
                                          </Button>
                                          <Button
                                            onClick={() => this.setState({ zoomLevel: zoomLevel + 1 })}
                                            disabled={zoomLevel === 2}
                                          >
                                            <i className="ic-plus ic-bg-primary" />
                                          </Button>
                                        </div>
                                      </div>
                                    )}
                                  />
                                ) : (
                                  <div className="uploaded-file">
                                    <i className={getFileTypeIcon(documentFormat)} />
                                    <Button
                                      variant="link"
                                      className="w-full"
                                      onClick={() => this.togglePreview(true, true)}
                                    >
                                      {filename}
                                    </Button>
                                    <Button
                                      variant="link"
                                      className="btn-icon-only"
                                      onClick={() => {
                                        setFieldValue('filename', '');
                                        setFieldValue('base64', '');
                                        setFieldValue('documentFormat', -1);
                                        setFieldValue('formats', []);
                                      }}
                                    >
                                      <i className="ic-delete text-danger" />
                                    </Button>
                                  </div>
                                )}
                              </>
                            )}
                            {errorFileFormat && (
                            <Alert type="danger">
                              Format de fichier non supporté.
                            </Alert>
                            )}
                            {errorFileSize && (
                            <Alert type="danger">
                              Fichier trop volumineux.
                              <br />
                              {' '}
                              Le DMP n&apos;accepte que des fichiers de taille inférieure à 5 Mo environ.
                            </Alert>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <div className="text">
                        <Form.Group>
                          <Form.Label>
                            Saisir le texte :
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            autoFocus
                            value={contribution}
                            onChange={e => setFieldValue('contribution', e.target.value)}
                            rows={4}
                          />
                        </Form.Group>
                      </div>
                    )}
                  </div>
                  {!showPreviewFullWidth && (
                  <>
                    <div className="right-side">{form}</div>
                    {isMobile && (
                    <div className="modal-footer">
                      <Button
                        size="sm"
                        variant="borderless-primary"
                        onClick={() => dispatch(push(getMobileLandingUrl(ins)))}
                      >
                        Annuler
                      </Button>
                      <ButtonWithLoader
                        disabled={!isValid}
                        label="Déposer"
                        onClick={handleSubmit}
                        loading={loading}
                      />
                    </div>
                    )}
                  </>
                  )}
                </>
              ) : (
                <div className="dmp-selector">
                  <div className="selector">
                    {dmpSelector === dmpSelectors.REMOTE && (
                      <ConnectedRemoteDMPSelector
                        directAuthenticationDMPStatus={directAuthenticationDMPStatus}
                        ins={ins}
                      />
                    )}
                    {dmpSelector === dmpSelectors.VIRTUAL_PRINTER && (
                      <ConnectedVirtualPrinterDMPSelector
                        directAuthenticationDMPStatus={directAuthenticationDMPStatus}
                        ins={ins}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </SectionStatusWrapper>
        </Popable>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    dmpconnect: {
      [apiSections.CPX_CARD_SECTION]: cpxCard,
      [apiSections.DOCUMENT_CONTENT_SECTION]: documentContentSection,
    },
    dmpconnectCPxConfiguration,
    dmpconnectInteropCodes: {
      [apiSections.INTEROPCODES]: { healthcareSettings, metaCategories, categories },
    },
    dmpconnectApplication: {
      mobilePreviewActive,
    },
  } = state;

  const { categories: currentCategories } = getCurrentFilter(state, 'search-documents', findDocumentParamsDefault);

  return {

    cpxCard,
    dmpconnectCPxConfiguration,
    healthcareSettings,
    healthcareSetting: getHealthcareSetting(state),
    categories: metaCategories,
    currentCategories: currentCategories || categories,
    documentContentSection,
    interopCategories: categories,
    uploadDocTitlePrefix: getUserConfigFromState(state, 'uploadDocTitlePrefix'),
    mssActive: isMssActive(state),
    mobilePreviewActive,
  };
}

DMPDocumentUpload.defaultProps = {
  sendDocumentSection: {},
  asPopup: true,
  formInitialValues: initialValues,
  onlyForm: false,
  currentCategories: [],
  showPreview: false,
  dmpSelector: null,
  directAuthenticationDMPStatus: {},
  documentContentSection: {},
  ins: null,
  noFileType: false,
  resetIns: false,
  onResetIns: null,
  allowDeleteFile: true,
  titleRoot: null,
  fullTitle: null,
  show: false,
  onClose: null,
  showBackButton: false,
  onBack: null,
  backLabel: null,
  backDisabled: false,
  certifiedIdentity: undefined,
  healthcareSetting: undefined,
  uploadDocTitlePrefix: '',
  mssActive: false,
  isMobile: false,
  mobilePreviewActive: false,
  cancelButtonLabel: 'Annuler',
  cancelButtonTooltip: '',
  submitButtonLabel: 'Déposer',
};

DMPDocumentUpload.propTypes = {
  dispatch: PropTypes.func.isRequired,
  cpxCard: PropTypes.object.isRequired,
  sendDocumentSection: PropTypes.object,
  dmpconnectCPxConfiguration: PropTypes.object.isRequired,
  asPopup: PropTypes.bool,
  formInitialValues: PropTypes.object,
  onlyForm: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  healthcareSettings: PropTypes.array.isRequired,
  interopCategories: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  currentCategories: PropTypes.array,
  showPreview: PropTypes.bool,
  dmpSelector: PropTypes.string,
  directAuthenticationDMPStatus: PropTypes.object,
  documentContentSection: PropTypes.object,
  ins: PropTypes.string,
  noFileType: PropTypes.bool,
  resetIns: PropTypes.bool,
  onResetIns: PropTypes.func,
  allowDeleteFile: PropTypes.bool,
  titleRoot: PropTypes.string,
  fullTitle: PropTypes.string,
  show: PropTypes.bool,
  submitButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  cancelButtonTooltip: PropTypes.string,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  backLabel: PropTypes.string,
  backDisabled: PropTypes.bool,
  showBackButton: PropTypes.bool,
  certifiedIdentity: PropTypes.object,
  healthcareSetting: PropTypes.string,
  uploadDocTitlePrefix: PropTypes.string,
  mssActive: PropTypes.bool,
  isMobile: PropTypes.bool,
  mobilePreviewActive: PropTypes.bool,
};

const ConnectedDMPUpload = connect(mapStateToProps,
  null,
  null,
  { forwardRef: true })(
  DMPDocumentUpload,
);

export default ConnectedDMPUpload;
