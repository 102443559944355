import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isLoading } from 'dmpconnectjsapp-base/helpers/common';
import DMPSelector from '../DMPSearch/DMPSelector';
import { setVirtualPrinterFileIns } from '../../dmpconnect/actions/dmpconnectVirtualPrinterActions';
import { getDirectAuthenticationDMPStatus } from '../../dmpconnect/actions';
import { isInsValid } from '../../dmpconnect/helpers';

class VirtualPrinterDMPSelector extends Component {
  selectINS(ins) {
    const { dispatch } = this.props;
    if (ins && isInsValid(ins)) {
      dispatch(getDirectAuthenticationDMPStatus(ins));
    }
    dispatch(setVirtualPrinterFileIns(ins));
  }

  render() {
    const {
      directAuthenticationDMPStatus,
    } = this.props;

    const loading = isLoading(directAuthenticationDMPStatus);
    return (
      <DMPSelector
        loading={loading}
        onSelect={selectedIns => this.selectINS(selectedIns)}
      />
    );
  }
}

VirtualPrinterDMPSelector.propTypes = {
  dispatch: PropTypes.func.isRequired,
  directAuthenticationDMPStatus: PropTypes.object.isRequired,
};
const ConnectedVirtualPrinterDMPSelector = connect()(VirtualPrinterDMPSelector);

export default ConnectedVirtualPrinterDMPSelector;
