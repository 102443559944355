import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavDropdown } from 'react-bootstrap';
import { getConfigurationValue } from 'dmpconnectjsapp-base/helpers/accessors';
import { connect } from 'react-redux';
import { API_TYPES, searchPanels } from '../../dmpconnect/constants';
import AccessByAvailableDMP from './AccessByAvailableDMP';
import AccessBySearch from './AccessBySearch';
import ApCVConnector from '../ApCV/ApCVConnector';
import AccessByVitaleCard from './AccessByVitaleCard';
import AccessByINS from './AccessByINS';

const DMPSelector = ({
  onSelect,
  esRestVitale,
  apiType,
}) => {
  const [activeSection, setActiveSection] = React.useState(searchPanels.ACCESS_BY_AVAILABLE_DMP_PANEL);
  return (
    <>
      <nav className="secondary-nav-bar navbar navbar-expand mt-3">
        <ul className="navbar-nav">
          <Nav.Link
            active={activeSection === searchPanels.ACCESS_BY_AVAILABLE_DMP_PANEL}
            onClick={() => setActiveSection(searchPanels.ACCESS_BY_AVAILABLE_DMP_PANEL)}
          >
            Mes patients DMP
          </Nav.Link>
          <Nav.Link
            active={activeSection === searchPanels.ACCESS_BY_SEARCH_PANEL}
            onClick={() => setActiveSection(searchPanels.ACCESS_BY_SEARCH_PANEL)}
          >
            Rechercher un DMP
          </Nav.Link>
          <Nav.Link
            active={activeSection === searchPanels.ACCESS_BY_VITALE_CARD_PANEL}
            onClick={() => setActiveSection(searchPanels.ACCESS_BY_VITALE_CARD_PANEL)}
          >
            Carte vitale
          </Nav.Link>
        </ul>
        <div className="ml-auto">
          <NavDropdown
            className="no-arrow ml-auto"
            id="extra-search"
            title={<i className="ic-navigation-menu-horizontal" style={{ fontSize: '0.3rem' }} />}
          >
            <NavDropdown.Item onClick={() => setActiveSection(searchPanels.ACCESS_BY_INS_PANEL)}>
              Rechercher un DMP par l&apos;INS
            </NavDropdown.Item>
          </NavDropdown>
        </div>
      </nav>
      <div className="mt-3">
        {activeSection === searchPanels.ACCESS_BY_AVAILABLE_DMP_PANEL && (
          <AccessByAvailableDMP
            selectIns={ins => onSelect(ins)}
            selector
          />
        )}
        {activeSection === searchPanels.ACCESS_BY_SEARCH_PANEL && (
          <AccessBySearch
            selectIns={ins => onSelect(ins)}
            selector
          />
        )}
        {activeSection === searchPanels.ACCESS_BY_VITALE_CARD_PANEL && (
          <>
            {(apiType !== API_TYPES.REST || (apiType === API_TYPES.REST && esRestVitale === true)) && (
              <ApCVConnector>
                <AccessByVitaleCard
                  selectIns={ins => onSelect(ins)}
                  selector
                />
              </ApCVConnector>
            )}
          </>
        )}
        {activeSection === searchPanels.ACCESS_BY_INS_PANEL && (
          <AccessByINS
            selectIns={ins => onSelect(ins)}
            selector
          />
        )}
      </div>
    </>
  );
};

DMPSelector.defaultProps = {
  esRestVitale: false,
};

DMPSelector.propTypes = {
  onSelect: PropTypes.func.isRequired,
  apiType: PropTypes.string.isRequired,
  esRestVitale: PropTypes.bool,
};

function mapStateToProps(state) {
  const { dmpconnectConnectorConfig, dmpconnectPersistedConnectorConfiguration } = state;
  return {
    esRestVitale: getConfigurationValue('esRestVitale', dmpconnectPersistedConnectorConfiguration),
    apiType: getConfigurationValue('apiType', dmpconnectConnectorConfig),
  };
}

export default connect(mapStateToProps)(DMPSelector);
