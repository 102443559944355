import { push }    from 'connected-react-router';
import {
  clearSection, dmpCommandFailureContextualizedType, dmpCommandSuccessContextualizedType,
}                  from 'dmpconnectjsapp-base/actions';
import {
  formatGetDocumentContentByUniqueIdParams,
}                  from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import commands    from 'dmpconnectjsapp-base/actions/config/commands';
import {
  accessModes, apiSections
}                  from 'dmpconnectjsapp-base/constants';
import {
  getCurrentPathname
}                  from 'dmpconnectjsapp-base/helpers/accessors';
import {
  documentStatuses, findDocumentParamsDefault
}                  from 'dmpconnectjsapp-base/helpers/findDocuments';
import {
  getAccessRightsProps, isTransactionAllowed, transactions
}                  from 'dmpconnectjsapp-base/rules/accessRights';
import {
  parseHrDocument
}                  from 'dmpconnectjsapp-base/utils/clinicalDocument';
import {
  encodeIns
}                  from 'dmpconnectjsapp-base/utils/insUtils';
import * as moment from 'moment';
import {
  toast
}                  from 'react-toastify';
import {
  call, put, select, take,
}                  from 'redux-saga/effects';
import {
  findDocuments, getAction, removeDocFromPersistantData, saveParsedHr, showUploadDocumentModal, updateDocumentsNextVersion,
}                  from '../actions';
import {
  dmpStatuses, userAuthorizationStatuses
}                  from '../constants';
import {
  getDirectAuthenticationStatus
}                  from '../helpers/directAuthenticationDMPStatus';
import {
  getCachedHr, getLastHR
}                  from '../helpers/documentUtils';
import {
  getCdaContent
}                  from '../helpers/findDocuments';
import {
  getUniqueUUid
}                  from '../rules/documentRules';
import {
  b64DecodeUnicode
}                  from '../utils/dataUtils';
import {
  saveStatInPersistantData
}                  from './persistantDataSaga';
import {
  handleSentDocument
}                  from './sendDocumentSaga';
import {
  getDocumentInfoFromCache, getFindDocumentsLastSearchTimeStamp, getNewDocFromCache,
}                  from './utilsSagas';

export const handleGetAndParseLastHR = function* (action) {
  const { data: { Documents = [] }, context: { subSection } } = action;
  const cachedHr                                              = yield select(getCachedHr, subSection);
  
  const lastHr = getLastHR(Documents);
  if (lastHr) {
    const documentId = getUniqueUUid(lastHr.s_uniqueId, lastHr.s_uuid);
    
    if (documentId !== (cachedHr.documentId || '')) {
      yield put(getAction(
        commands.getDocumentContentByUniqueId,
        apiSections.DOCUMENT_CONTENT_SECTION,
        {
          ...formatGetDocumentContentByUniqueIdParams(subSection, lastHr.s_uniqueId, lastHr.s_uuid),
          i_getCdaContent: 1,
        },
        {
          subSection: getUniqueUUid(lastHr.s_uniqueId, lastHr.s_uuid),
        },
      ));
      
      const result                                           = yield take(dmpCommandSuccessContextualizedType(apiSections.DOCUMENT_CONTENT_SECTION));
      const { context: { subSection: gotDocumentId }, data } = result;
      if (documentId === gotDocumentId) {
        const cdaContent = getCdaContent(data);
        const parsedHr   = parseHrDocument(b64DecodeUnicode(cdaContent));
        yield put(saveParsedHr(subSection, documentId, parsedHr));
      }
    }
  }
};

const findDocumentsProcess = function* (ins) {
  const findDocumentsParams = { ...findDocumentParamsDefault };
  const lastSearchTimestamp = yield select(getFindDocumentsLastSearchTimeStamp);
  yield put(clearSection(apiSections.FIND_DOCUMENTS_SECTION));
  findDocumentsParams.status = documentStatuses.ALL;
  yield put(findDocuments(ins, { ...findDocumentsParams, categories: [], lastSearchTimestamp }));
};

const findDocumentsProcessFromTo = function* (ins, from, to) {
  const findDocumentsParams                   = { ...findDocumentParamsDefault };
  findDocumentsParams.submissionStartDate     = from;
  findDocumentsParams.submissionStartDateHour = '00:00:00';
  findDocumentsParams.submissionEndDate       = to;
  findDocumentsParams.submissionEndDateHour   = '23:59:59';
  yield put(clearSection(apiSections.FIND_DOCUMENTS_SECTION));
  findDocumentsParams.status = documentStatuses.ALL;
  yield put(findDocuments(ins, { ...findDocumentsParams, categories: [] }));
};

export const refreshDocuments = function* (action) {
  
  const { command, context, data: { s_uniqueId } } = action;
  const { accessRights }                           = yield select(getAccessRightsProps);
  
  yield call(saveStatInPersistantData, action);
  yield take([
    dmpCommandSuccessContextualizedType(apiSections.SET_PERSISTANT_DATA),
    dmpCommandFailureContextualizedType(apiSections.SET_PERSISTANT_DATA),
  ]);
  
  const directAuthStatus = yield select(getDirectAuthenticationStatus, command.s_ins);
  const {
          ExistingTestAnswer: {
            i_dmpStatus        : dmpStatus,
            i_userAuthorization: authorization,
          } = {},
        }                = directAuthStatus;
  
  // lancer la recherche si le dmp existe
  // et
  // on est autorisé ou en bris de glace / centre15
  if (
    dmpStatus === dmpStatuses.DMPExist
    && (
      [accessModes.Centre15, accessModes.BrisDeGlace].includes(accessRights.accessMode)
      || authorization === userAuthorizationStatuses.AuthorizationExist
    )
  ) {
    if (isTransactionAllowed(accessRights, transactions.FIND_DOCUMENTS_META)) {
      yield call(findDocumentsProcess, command.s_ins);
      if (command.s_replacedDocumentUniqueId !== null && command.s_replacedDocumentUniqueId !== '') {
        yield take([
          dmpCommandSuccessContextualizedType(apiSections.FIND_DOCUMENTS_SECTION),
          dmpCommandFailureContextualizedType(apiSections.FIND_DOCUMENTS_SECTION),
        ]);
        
        // on lance une recherche sur la date d'ajout du document remplacé, pour le mettre à jour
        const previousDocument = yield select(
          getDocumentInfoFromCache,
          command.s_ins,
          getUniqueUUid(command.s_replacedDocumentUniqueId, context.replacedDocumentUUid),
        );
        if (previousDocument !== undefined) {
          const submissionDate = moment.utc(previousDocument.s_submissionDate, 'YYYYMMDDHHmmss');
          yield call(
            findDocumentsProcessFromTo,
            command.s_ins,
            submissionDate.format('DD/MM/YYYY'),
            submissionDate.format('DD/MM/YYYY'),
          );
          yield take([
            dmpCommandSuccessContextualizedType(apiSections.FIND_DOCUMENTS_SECTION),
            dmpCommandFailureContextualizedType(apiSections.FIND_DOCUMENTS_SECTION),
          ]);
        }
        
        // si on est sur la page document, on remplace par le nouveau doc
        const pathname = yield select(getCurrentPathname);
        if (pathname.indexOf('/document/') !== -1) {
          // cacher la modal de dépôt
          yield put(showUploadDocumentModal(false, null));
          // récupérer les infos du nouveau document
          const newDoc = yield select(getNewDocFromCache, command.s_ins, s_uniqueId, context.replacedDocumentUUid);
          
          // renvoi vers le nouveau document si page de details
          yield put(push(`/dmp/${encodeIns(command.s_ins)}/document/${encodeIns(getUniqueUUid(s_uniqueId, newDoc.s_uuid))}`));
        }
      }
    }
  }
  yield call(handleSentDocument, action);
};

export const refreshDocumentsAfterDelete = function* (action) {
  const {
          command: {
            s_uniqueId, s_documentUniqueId, s_uuid, s_documentUuid, s_ins,
          },
        } = action;
  
  toast.success('Le document a été supprimé avec succès');
  
  yield put(removeDocFromPersistantData(s_uniqueId || s_documentUniqueId, s_uuid || s_documentUuid, s_ins));
};

export const refreshDocumentsAfterDocumentUpdate = function* ({ context: { ins, subSection: uniqueUUid } }) {
  toast.success('Le document a été mis à jour avec succès');
  
  // si on est sur la page de contenu du document
  // const pathname = yield select(getCurrentPathname);
  // if (pathname.search('/document/') !== -1) {
  // rechercher uniquement le document mis à jour
  const document       = yield select(getDocumentInfoFromCache, ins, uniqueUUid);
  const submissionDate = moment.utc(document.s_submissionDate, 'YYYYMMDDHHmmss');
  
  const params                   = { ...findDocumentParamsDefault };
  params.submissionStartDate     = submissionDate.format('DD/MM/YYYY');
  params.submissionStartDateHour = '00:00:00';
  params.submissionEndDate       = submissionDate.format('DD/MM/YYYY');
  params.submissionEndDateHour   = '23:59:59';
  params.status                  = documentStatuses.ALL;
  params.categories              = [];
  
  yield put(clearSection(apiSections.FIND_DOCUMENTS_SECTION));
  yield put(findDocuments(ins, params));
  // } else {
  //   yield call(findDocumentsProcess, ins);
  // }
};
export const refreshDocumentsAfterParamsChanged  = function* ({ ins, refresh }) {
  if (refresh) {
    yield call(findDocumentsProcess, ins);
  }
};

export const checkAndUpdatePreviousVersions = function* (action) {
  const { command: { s_ins }, data: { Documents: receivedDocs = [] } } = action;
  const cachedDocs                                                     = yield select((state) => {
    const {
            dmpconnectDocumentsCache: {
              [s_ins]: { documents } = {},
            } = {},
          } = state;
    return documents;
  });
  
  const receivedDocsWithAPreviousVersion = receivedDocs.filter(doc => !!doc.s_previousUUId);
  const receivedDocsPreviousIds          = receivedDocs.map(doc => doc.s_previousUUId);
  const relatedCachedDocs                = Object.values(cachedDocs)
    .filter(doc => !doc.s_nextUUId && receivedDocsPreviousIds.includes(doc.s_uuid));
  
  const docsToUpdate = relatedCachedDocs.reduce((updates, doc) => {
    const lastVersion = receivedDocsWithAPreviousVersion.find(d => d.s_previousUUId === doc.s_uuid);
    if (lastVersion) {
      return [...updates, {
        uniqueUUid: getUniqueUUid(doc.s_uniqueId, doc.s_uuid),
        nextUUId  : lastVersion.s_uuid,
      }];
    }
    return updates;
  }, []);
  
  if (docsToUpdate.length > 0) {
    yield put(updateDocumentsNextVersion(docsToUpdate, s_ins));
  }
};
