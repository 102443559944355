import React from 'react';
import {
  API_TYPES,
  apiSections,
  vitaleCardMonitoringStatuses,
} from 'dmpconnectjsapp-base/constants';
import {
  isReady, getError, isLoading, hasError,
} from 'dmpconnectjsapp-base/helpers/common';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import {
  formatGetApCvContext,
  formatGetCardParams, formatGetNfcCardParams, formatReleaseApCvContext,
} from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import {
  getApiType,
  getConfigurationValue, getUserConfiguration,
} from 'dmpconnectjsapp-base/helpers/accessors';
import { clearSection, startVitaleMonitoring, stopVitaleMonitoring } from 'dmpconnectjsapp-base/actions';
import onScan from 'onscan.js';
import { vitaleDataType } from 'dmpconnectjsapp-base/helpers/vitaleData';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import { getAction, readVitaleProcess, setVitaleCardSerialNumber } from '../../dmpconnect/actions';

import { isModalError } from '../../dmpconnect/helpers/error';
import ErrorMessage from '../Common/Message/ErrorMessage';
import CheckAccessRights from '../AccessRights/CheckAccessRights';
import Alert from '../Common/Message/Alert';
import { getReaderName } from '../../dmpconnect/helpers/readers';

const VitaleCardReaderProvider = ({
  dispatch,
  vitaleReady,
  vitaleCardReady,
  vitaleLoading,
  vitaleCardLoading,
  children,
  vitaleCardReader,
  showLoading,
  autoRead,
  monitor,
  isVitaleMonitoringReady,
  vitaleCardMonitoringStatus,
  nfcCardReader, nfcReady, nfcLoading, nfcData,
  apCvConfig,
  billingNumber,
  codeSpeAMO,
  apCvContext,
  vitaleData,
  vitaleCardMonitoringError,
  vitaleCardMonitoringSerialNumber,
  vitaleCardSerialNumber,
  releaseApCvContext,
  apCvContextToDestroy,
  apiType,
  vitaleXmlLoading,
  vitaleCardReaderName,
}) => {
  const [nfcReadTimeout, setNfcReadTimeout] = React.useState(0);
  const [nfcReadError, setNfcReadError] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState('');
  const [qrcode, setQrcode] = React.useState('');
  const patients = React.useMemo(() => vitaleData.Patients, [vitaleData.Patients]);
  const [qrCodeMonitoring, setQrCodeMonitoring] = React.useState(monitor);
  const [nfcMonitoring, setNfcMonitoring] = React.useState(monitor);
  const [getApcvContextRunning, setGetApcvContextRunning] = React.useState(false);

  const resetVitaleData = React.useCallback(() => {
    dispatch(getAction(
      commands.getVitaleCard,
      apiSections.VITALE_SECTION,
      formatGetCardParams(vitaleCardReader, vitaleCardReaderName),
      {},
    ));
    dispatch(clearSection(apiSections.VITALE_CARD_SECTION));
    dispatch(clearSection(apiSections.GET_APCV_CONTEXT));
    dispatch(clearSection(apiSections.GET_NFC_APCV_USER_DATA));
    dispatch(clearSection(apiSections.VITALE_DATA_SECTION));
    dispatch(clearSection(apiSections.VITALE_XML_CONTENT_SECTION));
    dispatch(clearSection(apiSections.INS_FROM_VITALE_CARD));
  }, [vitaleCardReader, vitaleCardReaderName]);

  const callReleaseApCvContext = () => {
    if (apCvConfig.active && apCvContextToDestroy === true) {
      dispatch(clearSection(apiSections.RELEASE_APCV_CONTEXT));
      dispatch(getAction(
        commands.releaseApCvContext,
        apiSections.RELEASE_APCV_CONTEXT,
        formatReleaseApCvContext({
          idam: apCvConfig.idam,
          numAm: apCvConfig.numAm,
          lpsName: apCvConfig.lpsName,
          lpsVersion: apCvConfig.lpsVersion,
          codeSpeAMO,
          billingNumber,
        }),
        // { silentError: true },
      ));
    }
  };

  const getApCVContext = (token = undefined) => {
    setGetApcvContextRunning(true);
    dispatch(clearSection(apiSections.VITALE_CARD_SECTION));
    dispatch(clearSection(apiSections.VITALE_DATA_SECTION));
    dispatch(clearSection(apiSections.GET_APCV_CONTEXT));
    dispatch(getAction(
      commands.getApCvContext,
      apiSections.GET_APCV_CONTEXT,
      formatGetApCvContext({
        idam: apCvConfig.idam,
        numAm: apCvConfig.numAm,
        lpsName: apCvConfig.lpsName,
        lpsVersion: apCvConfig.lpsVersion,
        codeSpeAMO,
        billingNumber,
        token,
      }),
      {
        silentError: true,
      },
    ));
  };

  const readVitaleCard = () => {
    callReleaseApCvContext();
    dispatch(readVitaleProcess());
  };

  React.useEffect(() => {
    if (isLoading(releaseApCvContext)) {
      toast.info('Destruction du contexte ApCV en cours', {
        toastId: 'releaseContextApCV',
        autoClose: false,
      });
    }
    if (isReady(releaseApCvContext)) {
      dispatch(clearSection(apiSections.GET_APCV_CONTEXT));
      dispatch(clearSection(apiSections.RELEASE_APCV_CONTEXT));
      toast.update('releaseContextApCV', {
        render: 'Contexte ApCV détruit',
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
    }
    if (hasError(releaseApCvContext)) {
      toast.update('releaseContextApCV', {
        render: 'Erreur durant la destruction du contexte ApCV',
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
    }
  }, [releaseApCvContext]);

  React.useEffect(() => {
    if (Number(vitaleCardReader) >= 0 && !vitaleReady && !vitaleLoading) {
      dispatch(clearSection(apiSections.VITALE_SECTION));
      dispatch(clearSection(apiSections.VITALE_CARD_SECTION));
      dispatch(getAction(
        commands.getVitaleCard,
        apiSections.VITALE_SECTION,
        formatGetCardParams(vitaleCardReader, vitaleCardReaderName),
        {},
      ));
    }
  }, [vitaleCardReader, vitaleCardReaderName, vitaleReady, vitaleLoading]);

  React.useEffect(() => {
    if (apCvConfig.active && Number(nfcCardReader) >= 0 && !nfcReady && !nfcLoading) {
      dispatch(clearSection(apiSections.GET_NFC_CARD));
      dispatch(clearSection(apiSections.GET_NFC_APCV_USER_DATA));
      dispatch(getAction(
        commands.getNfcCard,
        apiSections.GET_NFC_CARD,
        formatGetNfcCardParams(nfcCardReader),
        {},
      ));
    }
  }, [apCvConfig.active, nfcCardReader, nfcReady, nfcLoading]);

  React.useEffect(() => {
    if (apCvConfig.active && nfcMonitoring) {
      let noCardError = true;
      if (hasError(nfcData)) {
        const error = getError(nfcData);

        noCardError = (
          error && error.s_apiErrorExtendedInformations
          && error.s_apiErrorExtendedInformations.indexOf('SCARD_W_REMOVED_CARD') !== -1
        );

        if (!noCardError) {
          setNfcReadError(error);
        } else {
          setNfcReadError(undefined);
        }
      } else {
        setNfcReadError(undefined);
      }

      if (!isReady(vitaleData) && !isLoading(vitaleData) && nfcReady && !isReady(nfcData) && !isLoading(nfcData)) {
        if (nfcReadTimeout) clearTimeout(nfcReadTimeout);
        setNfcReadTimeout(setTimeout(() => {
          dispatch(getAction(
            commands.getNfcApCvUserData,
            apiSections.GET_NFC_APCV_USER_DATA,
            null,
            { silentError: true },
          ));
        }, 2000));
      }
    }
  }, [apCvConfig.active, nfcReady, nfcData, vitaleData, nfcMonitoring]);

  React.useEffect(() => {
    if (nfcMonitoring && (isReady(vitaleData) || isLoading(vitaleData) || hasError(vitaleData))) {
      clearInterval(nfcReadTimeout);
    }
  }, [nfcMonitoring, vitaleData]);

  React.useEffect(() => {
    if (
      !getApcvContextRunning
      && apCvConfig.active
      && isReady(nfcData)
      && !isReady(apCvContext)
      && !isLoading(apCvContext)
      && !isLoading(apCvContext)
      && !hasError(apCvContext)
    ) {
      setNfcMonitoring(false);
      getApCVContext(nfcData.s_dataInBase64);
    }
  }, [nfcData, apCvContext, apCvConfig.active, getApcvContextRunning]);

  React.useEffect(() => {
    if (
      !getApcvContextRunning
      && qrcode
      && !isReady(vitaleData) && !isLoading(vitaleData) && !hasError(vitaleData) && !isLoading(apCvContext)) {
      setQrCodeMonitoring(false);
      getApCVContext(qrcode);
      setQrcode(undefined);
    }
  }, [qrcode, vitaleData, getApcvContextRunning, apCvContext]);

  React.useEffect(() => {
    if (autoRead === true && vitaleReady && !vitaleCardLoading && !vitaleCardReady) {
      readVitaleCard();
    }
  }, [autoRead, vitaleReady, vitaleCardReady, vitaleCardLoading]);

  React.useEffect(() => {
    if (monitor && vitaleReady && !isVitaleMonitoringReady) {
      dispatch(startVitaleMonitoring());
    }

    if (monitor && isVitaleMonitoringReady && !isReady(nfcData)) {
      if (
        vitaleCardMonitoringStatus === vitaleCardMonitoringStatuses.newCard
        && vitaleCardSerialNumber !== vitaleCardMonitoringSerialNumber
      ) {
        dispatch(setVitaleCardSerialNumber(vitaleCardMonitoringSerialNumber));
        dispatch(clearSection(apiSections.VITALE_CARD_SECTION));
        dispatch(clearSection(apiSections.VITALE_DATA_SECTION));
        dispatch(clearSection(apiSections.GET_APCV_CONTEXT));
        readVitaleCard();
      } else if (vitaleCardMonitoringStatus === vitaleCardMonitoringStatuses.NoCard) {
        dispatch(clearSection(apiSections.VITALE_CARD_SECTION));
        dispatch(setVitaleCardSerialNumber(null));
        if (vitaleData.type === vitaleDataType.VITALE_CARD) {
          dispatch(clearSection(apiSections.VITALE_DATA_SECTION));
        }
      }
    }
  }, [
    monitor,
    vitaleReady,
    isVitaleMonitoringReady,
    vitaleCardMonitoringStatus,
    isReady(nfcData),
    vitaleCardSerialNumber,
    vitaleCardMonitoringSerialNumber,
  ]);

  React.useEffect(() => {
    if (vitaleLoading || nfcLoading) {
      setLoading(true);
      setLoadingMessage('Chargement');
    } else if (vitaleCardLoading || (apiType === API_TYPES.REST && vitaleXmlLoading)) {
      setLoading(true);
      setLoadingMessage('Lecture de la carte vitale en cours');
    // } else if (isLoading(nfcData)) {
    //   setLoading(true);
    //   setLoadingMessage('Lecture NFC');
    } else if (isLoading(apCvContext)) {
      setLoading(true);
      setLoadingMessage('Récupération du contexte ApCV en cours');
    } else {
      setLoadingMessage('');
      setLoading(false);
    }
  }, [vitaleLoading, vitaleCardLoading, vitaleXmlLoading, nfcLoading, isLoading(nfcData), isLoading(apCvContext)]);

  React.useEffect(() => {
    if (qrCodeMonitoring && !onScan.isAttachedTo(document)) {
      onScan.attachTo(document, {
        keyCodeMapper: (oEvent) => {
          // return chars that are not letters or numbers and not special keys
          if (!(
            (oEvent.which >= 48 && oEvent.which <= 90)
            || (oEvent.which >= 106 && oEvent.which <= 111)
          ) && oEvent.location === 0) {
            return oEvent.key;
          }
          // Fall back to the default decoder in all other cases
          return onScan.decodeKeyEvent(oEvent);
        },
        onScan: (sCode) => { // Alternative to document.addEventListener('scan')
          setQrcode(sCode);
        },
      });
    } else if (!qrCodeMonitoring && onScan.isAttachedTo(document)) {
      onScan.detachFrom(document);
    }
    return () => {
      if (qrCodeMonitoring && onScan.isAttachedTo(document)) {
        onScan.detachFrom(document);
      }
      dispatch(stopVitaleMonitoring());
      clearInterval(nfcReadTimeout);
    };
  }, [qrCodeMonitoring]);

  React.useEffect(() => {
    if (isReady(apCvContext) || hasError(apCvContext)) {
      setGetApcvContextRunning(false);
    }
  }, [apCvContext]);


  return (
    <>
      {!isReady(vitaleData) && (
        <div className="centered h-centered">
          <div className="apcv-container mt-3">
            {apCvConfig.active === true ? (
              <div className="typography-big-text-title">Veuillez lire la carte vitale par l&apos;un des moyens disponibles :</div>
            ) : (
              <div className="typography-big-text-title">Veuillez lire la carte vitale</div>
            )}
            <div className="apcv-methods">
              <div className="vitale">
                <div className="method-logo" />
                <div className="method-action">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => readVitaleCard()}
                    disabled={loading || (!monitor && (nfcMonitoring || qrCodeMonitoring))}
                  >
                    Lire la Carte Vitale
                  </button>
                </div>
                <div className="method-explain">Insérer la carte vitale dans le lecteur</div>
              </div>
              {apCvConfig.active === true && (
                <>
                  {apCvConfig.nfcReaderActive === true && (
                  <div className={`nfc ${Number(nfcCardReader) === -1 ? 'disabled' : ''}`}>
                    <div className="method-logo" />
                    <div className="method-action">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        disabled={loading || (!monitor && (nfcMonitoring || qrCodeMonitoring))}
                        onClick={() => setNfcMonitoring(true)}
                      >
                        Lire l&apos;appli Carte Vitale
                      </button>
                    </div>
                    <div className="method-explain">
                      Posez le smartphone sur le lecteur.
                    </div>
                  </div>
                  )}
                  {apCvConfig.qrCodeReaderActive === true && (
                  <div className="qrcode">
                    <div className="method-logo" />
                    <div className="method-action">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        disabled={loading || (!monitor && (nfcMonitoring || qrCodeMonitoring))}
                        onClick={() => setQrCodeMonitoring(true)}
                      >
                        Lire le QR Code appli Carte Vitale
                      </button>
                    </div>
                    <div className="method-explain">
                      Scannez le QR code avec la douchette.
                    </div>
                  </div>
                  )}
                </>
              )}
            </div>
            <div className="apcv-status">
              {apCvConfig.active === true && apCvConfig.showRetreiveContext === true && (
              <div className="restitutionContext d-flex justify-content-end mb-2">
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={() => getApCVContext()}
                  disabled={loading || (!monitor && (nfcMonitoring || qrCodeMonitoring))}
                >
                  Obtenir le dernier contexte ApCV
                </Button>
              </div>
              )}
              {Number(vitaleCardReader) === -1 && (
                <Alert type="warning">
                  <div className="d-flex align-items-center justify-content-between">
                    <span>Aucun lecteur de carte vitale configuré</span>
                    <Button className="ml-3" size="sm" variant="outline-secondary" as={Link} to="/configuration/cards">
                      Configurer le lecteur
                    </Button>
                  </div>
                </Alert>
              )}
              {apCvConfig.nfcReaderActive === true && apCvConfig.active === true && Number(nfcCardReader) === -1 && (
                <Alert type="warning" iconAlign="center">
                  <div className="d-flex align-items-center justify-content-between">
                    <span>Aucun lecteur NFC configuré</span>
                    <Button className="ml-3" size="sm" variant="outline-secondary" as={Link} to="/configuration/cards">
                      Configurer le lecteur
                    </Button>
                  </div>
                </Alert>
              )}
              {!monitor && qrCodeMonitoring && (
                <Alert type="info" iconAlign="center">
                  <div className="d-flex align-items-center justify-content-between">
                    <span>
                      Veuillez scanner le QR Code de l&apos;appli Carte Vitale.
                    </span>
                    <Button size="sm" variant="outline-secondary" onClick={() => setQrCodeMonitoring(false)}>
                      Annuler
                    </Button>
                  </div>
                </Alert>
              )}
              {!monitor && nfcMonitoring && (
                <Alert type="info" iconAlign="center">
                  <div className="d-flex align-items-center justify-content-between">
                    <span>
                      Veuillez poser le smaprtphone sur le lecteur NFC.
                    </span>
                    <Button size="sm" variant="outline-secondary" onClick={() => setNfcMonitoring(false)}>
                      Annuler
                    </Button>
                  </div>
                </Alert>
              )}
              {hasError(vitaleData) && !isModalError(getError(vitaleData)) && (
                <ErrorMessage error={getError(vitaleData)} showDetails />
              )}
              {vitaleCardMonitoringError && (
                <ErrorMessage title="Erreur Carte Vitale" message={vitaleCardMonitoringError} error={vitaleCardMonitoringError} />
              )}
              {nfcReadError && (
                <ErrorMessage title="Lecture NFC" message="La lecture NFC a échoué" error={nfcReadError} showDetails />
              )}
              {hasError(apCvContext) && (
                <ErrorMessage error={getError(apCvContext)} showDetails />
              )}
              {loading === true && showLoading === true && (
                <Alert type="info">
                  <div className="d-flex align-items-center justify-content-between">
                    <span>{loadingMessage}</span>
                    <Spinner animation="border" size="sm" />
                  </div>
                </Alert>
              )}
            </div>
          </div>
        </div>
      )}
      {!(loading === true && showLoading === true) && (
        <>
          {React.cloneElement(children, {
            vitaleCardStatus: vitaleData.i_cardStatus,
            patients,
            dataType: vitaleData.type,
            vitaleLoading: vitaleLoading || nfcLoading,
            vitaleCardReady: isReady(vitaleData),
            vitaleCardLoading: isLoading(vitaleData),
            vitaleCardReader,
            nfcCardReader,
            readVitaleCard,
            resetVitaleData,
          })}
        </>
      )}
    </>
  );
};

function mapStateToProps(state, props) {
  const { accessRights } = props;
  const {
    dmpconnect: {
      [apiSections.VITALE_DATA_SECTION]: vitaleData,
      [apiSections.VITALE_CARD_SECTION]: vitaleCard,
      [apiSections.VITALE_XML_CONTENT_SECTION]: vitaleXml,
      [apiSections.VITALE_SECTION]: vitale,
      [apiSections.GET_NFC_CARD]: nfcCard,
      [apiSections.GET_NFC_APCV_USER_DATA]: nfcData,
      [apiSections.GET_APCV_CONTEXT]: apCvContext,
      [apiSections.RELEASE_APCV_CONTEXT]: releaseApCvContext,
    },
    dmpconnectMonitoring: {
      vitaleCard: {
        message: vitaleMonitoring = {},
      } = {},
    } = {},
    dmpconnectApplication: {
      vitaleCardSerialNumber,
      apCvContextToDestroy,
    },
    dmpconnectPersistedConnectorConfiguration,
    dmpconnectApCvConfiguration,
    dmpconnectCPxConfiguration,
  } = state;


  return {
    apiType: getApiType(state),
    apCvConfig: dmpconnectApCvConfiguration,
    billingNumber: getUserConfiguration(
      { s_internalId: accessRights.psId },
      'billingNumber',
      dmpconnectCPxConfiguration,
    ),
    codeSpeAMO: getUserConfiguration(
      { s_internalId: accessRights.psId },
      'codeSpeAMO',
      dmpconnectCPxConfiguration,
      '99',
    ),
    nfcData,
    apCvContext,
    releaseApCvContext,
    isVitaleMonitoringReady: isReady(vitaleMonitoring),
    vitaleCardMonitoringStatus: vitaleMonitoring.i_cardStatus || null,
    vitaleCardMonitoringError: vitaleMonitoring.s_apiErrorDescription || null,
    vitaleCardMonitoringSerialNumber: vitaleMonitoring.s_serial || null,
    vitaleLoading: isLoading(vitale),
    vitaleReady: isReady(vitale),
    nfcReady: isReady(nfcCard),
    nfcLoading: isLoading(nfcCard),
    vitaleCardLoading: isLoading(vitaleCard),
    vitaleCardReady: isReady(vitaleCard),
    vitaleXmlLoading: isLoading(vitaleXml),
    vitaleData,
    vitaleCardReader: Number(getConfigurationValue('vitaleCardReader', dmpconnectPersistedConnectorConfiguration)),
    vitaleCardReaderName: getReaderName(state, 'vitaleCardReader'),
    nfcCardReader: Number(getConfigurationValue('nfcCardReader', dmpconnectPersistedConnectorConfiguration)),
    vitaleCardSerialNumber,
    apCvContextToDestroy,
  };
}

VitaleCardReaderProvider.propTypes = {
  apiType: PropTypes.string.isRequired,
  apCvContext: PropTypes.object,
  releaseApCvContext: PropTypes.object,
  apCvConfig: PropTypes.object,
  billingNumber: PropTypes.string,
  codeSpeAMO: PropTypes.string,
  children: PropTypes.node.isRequired,
  vitaleData: PropTypes.object,
  nfcData: PropTypes.object,
  vitaleLoading: PropTypes.bool,
  vitaleReady: PropTypes.bool,
  nfcLoading: PropTypes.bool,
  nfcReady: PropTypes.bool,
  vitaleXmlLoading: PropTypes.bool,
  vitaleCardLoading: PropTypes.bool,
  vitaleCardReady: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  vitaleCardReader: PropTypes.number,
  nfcCardReader: PropTypes.number,
  showLoading: PropTypes.bool,
  autoRead: PropTypes.bool,
  monitor: PropTypes.bool,
  isVitaleMonitoringReady: PropTypes.bool,
  vitaleCardMonitoringStatus: PropTypes.number,
  vitaleCardMonitoringError: PropTypes.string,
  vitaleCardMonitoringSerialNumber: PropTypes.string,
  vitaleCardSerialNumber: PropTypes.string,
  vitaleCardReaderName: PropTypes.string,
  apCvContextToDestroy: PropTypes.bool,
};
VitaleCardReaderProvider.defaultProps = {
  apCvContext: {},
  releaseApCvContext: {},
  apCvConfig: {},
  billingNumber: '',
  codeSpeAMO: '99',
  vitaleData: {},
  nfcData: {},
  vitaleLoading: false,
  vitaleReady: false,
  nfcLoading: false,
  nfcReady: false,
  vitaleXmlLoading: false,
  vitaleCardLoading: false,
  vitaleCardReady: false,
  vitaleCardReader: -1,
  nfcCardReader: -1,
  showLoading: true,
  autoRead: false,
  monitor: false,
  isVitaleMonitoringReady: false,
  vitaleCardMonitoringStatus: null,
  vitaleCardMonitoringError: null,
  vitaleCardMonitoringSerialNumber: null,
  vitaleCardSerialNumber: null,
  vitaleCardReaderName: null,
  apCvContextToDestroy: false,
};


const ConnectedVitaleCardReaderProvider = CheckAccessRights(connect(mapStateToProps)(VitaleCardReaderProvider));

export default ConnectedVitaleCardReaderProvider;
