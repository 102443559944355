import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { clearSection } from 'dmpconnectjsapp-base/actions';
import { canUpload, transactions } from 'dmpconnectjsapp-base/rules/accessRights';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { formatFindPatientsParams } from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import { getConfigurationValue } from 'dmpconnectjsapp-base/helpers/accessors';
import { getError, isLoading, isReady } from 'dmpconnectjsapp-base/helpers/common';
import {
  Button, Form, InputGroup, Modal,
} from 'react-bootstrap';
import checkAccessRights from '../AccessRights/CheckAccessRights';


import {
  getAction, selectCertifiedIdentity, setBackPage,
  setFindPatientsParams,
} from '../../dmpconnect/actions';
import SearchForm from './Form/SearchForm';
import DMPDate from '../Common/Date/DMPDate';
import ErrorMessage from '../Common/Message/ErrorMessage';
import DroppableNameCell from './DroppableNameCell';
import SimpleTable from '../Common/Table/SimpleTable';
import DMPStatusProvider from '../DMPStatus/DMPStatusProvider';
import DMPStatusActions from '../DMPStatus/DMPStatusActions';
import DMPStatusExtraActions from '../DMPStatus/DMPStatusExtraActions';
import { findPatientsDefaultValues } from '../../dmpconnect/helpers/searchUtils';
import { getFullName } from '../../dmpconnect/helpers/accessibleDMPList';
import Alert from '../Common/Message/Alert';
import { searchPanels } from '../../dmpconnect/constants';
import { backPagesTypes } from '../../dmpconnect/helpers/nav';
import { isModalError } from '../../dmpconnect/helpers/error';
import { isMssActive } from '../../dmpconnect/helpers';
import MobilePatientList from './MobilePatientList';
import getIsMobileFromState from '../../dmpconnect/helpers/isMobile';
import Loading from '../Common/Loading/Loading';

const AccessBySearch = ({
  dispatch,
  selector,
  selectIns,
  findPatients,
  findPatientsParams,
  showUploadForm,
  accessRights,
  debugUloadCPE,
  noDashboard,
  mssActive,
  isMobile,
}) => {
  const [showForm, setShowForm] = React.useState(false);

  React.useEffect(() => {
    if (!selector) {
      dispatch(setBackPage(backPagesTypes.DASHBOARD, `/home/${searchPanels.ACCESS_BY_SEARCH_PANEL}`));
    }
  }, []);

  const resetParamsAndSection = () => {
    dispatch(clearSection(apiSections.FIND_PATIENTS_SECTION));
    dispatch(setFindPatientsParams(findPatientsDefaultValues));
  };

  const search = (values) => {
    dispatch(clearSection(apiSections.FIND_PATIENTS_SECTION));
    dispatch(setFindPatientsParams(values));
    dispatch(getAction(
      commands.findPatient,
      apiSections.FIND_PATIENTS_SECTION,
      formatFindPatientsParams(
        values.name,
        values.givenName,
        values.city,
        values.postalCode,
        values.birthday,
        values.sex,
        values.approxName,
        values.approxCity,
      ),
    ));
  };

  const globalSearch = (event) => {
    const { target: { value } } = event;
    const [name, givenName, birthday] = value.split(' ');

    search({
      name, givenName, birthday, approxName: 1,
    });
  };

  const selectDMP = (ins) => {
    if (!selector) dispatch(selectCertifiedIdentity(null));
    selectIns(ins);
  };

  const displayUpload = canUpload(accessRights) || debugUloadCPE;

  const columns = React.useMemo(() => {
    let tmpColumns = [
      {
        Header: 'Patient',
        id: 'patientName',
        accessor: d => getFullName(d.CivilStatus.s_name, d.CivilStatus.s_given, d.s_birthName),
        Cell: ({ row, value }) => (
          <>
            {!selector ? (
              <>
                {displayUpload ? (
                  <DroppableNameCell
                    // eslint-disable-next-line react/prop-types
                    onDrop={acceptedFiles => showUploadForm(acceptedFiles, row.original.Ins.s_ins + row.original.Ins.s_insType)}
                    // eslint-disable-next-line react/prop-types
                    ins={row.original.Ins.s_ins + row.original.Ins.s_insType}
                    text={value}
                  />
                ) : (
                  <span className="typography-big-text-title">{value}</span>
                )}
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-link btn-sm typography-big-text-title"
                  // eslint-disable-next-line react/prop-types
                  onClick={() => selectDMP(row.original.Ins.s_ins + row.original.Ins.s_insType)}
                >
                  {value}
                </button>
              </>
            )}
          </>
        ),
      },
      {
        Header: 'Né(e) le',
        id: 's_birthDay',
        Cell: row => (<DMPDate dmpDate={row.value} className="typography-default-text-title" />),
        accessor: d => d.s_birthDay,
        filterable: false,
        width: 90,

      },
      {
        id: 'dmp-access',
        Header: 'Accès au dossier patient',
        Cell: ({ row }) => (
          <DMPStatusProvider getOnMount certifiedIns={`${row.original.Ins.s_ins}${row.original.Ins.s_insType}`}>
            <DMPStatusActions
              accessDMP={ins => selectDMP(ins)}
              aldActive={false}
            />
          </DMPStatusProvider>
        ),
      },
      {
        id: 'actions',
        Header: 'Actions',
        Cell: ({ row }) => (
          <DMPStatusProvider certifiedIns={`${row.original.Ins.s_ins}${row.original.Ins.s_insType}`} showError={false}>
            <DMPStatusExtraActions showUploadForm={ins => showUploadForm([], ins)} mssActive={mssActive} />
          </DMPStatusProvider>
        ),
      },
    ];

    if (selector) {
      tmpColumns = tmpColumns.filter(c => !['actions', 'dmp-access'].includes(c.id));
    }
    return tmpColumns;
  }, []);

  const error = getError(findPatients);
  const loading = isLoading(findPatients);
  const ready = isReady(findPatients);
  const { Patients = [] } = findPatients;

  return (
    <div id="accessBySearchDmpPanel">
      {error && !isModalError(error) && <ErrorMessage error={error} />}
      {ready && Patients && Patients.length === 0 && (
      <Alert type="danger">
        Aucun DMP trouvé avec ces critères de recherche
      </Alert>
      )}
      {!noDashboard && !isMobile && (
        <SearchForm
          initialValues={findPatientsParams}
          onSubmit={values => search(values)}
          resetParamsAndResults={() => resetParamsAndSection()}
          loading={loading}
        />
      )}
      {isMobile && (
      <>
        <InputGroup className="mb-3">
          <InputGroup.Prepend className="no-background">
            <InputGroup.Text>
              <Form.Label htmlFor="patients-search">
                <i className="ic-search ic-bg-text" />
              </Form.Label>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            id="patients-search"
            placeholder="Rechercher un DMP"
            onBlur={event => globalSearch(event)}
          />
          <InputGroup.Append className="no-background">
            <InputGroup.Text>
              <Button
                variant="link"
                className="btn-icon-only"
                onClick={() => setShowForm(true)}
                disabled={loading}
              >
                {loading ? (
                  <Loading size="sm" />
                ) : (
                  <i className="ic-edition-sliders" />
                )}
              </Button>
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>

        <Modal
          id="searchModal"
          size="lg"
          centered
          backdrop="static"
          keyboard={false}
          show={showForm}
          onHide={() => setShowForm(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title as="h5">Rechercher un DMP</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SearchForm
              initialValues={findPatientsParams}
              onSubmit={(values) => {
                setShowForm(false);
                search(values);
              }}
              resetParamsAndResults={() => resetParamsAndSection()}
              loading={loading}
              noButtons
            />
          </Modal.Body>
        </Modal>
      </>
      )}
      {ready && Patients && (
      <>
        {noDashboard && Patients && Patients.length > 1 && (
        <div className="typography-big-text-title mb-3">
          Plusieurs DMP trouvés, veuillez sélectionner le DMP à ouvrir :
        </div>
        )}
        {isMobile ? (
          <MobilePatientList
            patients={Patients.map(dmp => ({
              name: dmp.CivilStatus.s_name,
              given: dmp.CivilStatus.s_given,
              birthName: dmp.s_birthName,
              birthday: dmp.s_birthDay,
              sex: dmp.i_sex,
              ins: `${dmp.Ins.s_ins}${dmp.Ins.s_insType}`,
            }))}
            accessDMP={ins => selectDMP(ins)}
          />
        ) : (
          <SimpleTable columns={columns} data={Patients} striped />
        )}
      </>
      )}
    </div>
  );
};

AccessBySearch.defaultProps = {
  showUploadForm: null,
  debugUloadCPE: false,
  selector: false,
  noDashboard: false,
  mssActive: false,
};

AccessBySearch.propTypes = {
  findPatientsParams: PropTypes.object.isRequired,
  findPatients: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  showUploadForm: PropTypes.func,
  accessRights: PropTypes.object.isRequired,
  debugUloadCPE: PropTypes.bool,
  selectIns: PropTypes.func.isRequired,
  selector: PropTypes.bool,
  noDashboard: PropTypes.bool,
  mssActive: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    dmpconnect: {
      [apiSections.FIND_PATIENTS_SECTION]: findPatients,
    },
    dmpconnectUser: { findPatientsParams },
    dmpconnectConnectorConfig,
    dmpConnectPersistedAppConfiguration,
  } = state;
  return {
    findPatients,
    findPatientsParams,
    debugUloadCPE: getConfigurationValue('debugUploadCPE', dmpconnectConnectorConfig),
    noDashboard: getConfigurationValue('noDashboard', dmpConnectPersistedAppConfiguration),
    mssActive: isMssActive(state),
    isMobile: getIsMobileFromState(state),
  };
}

const ConnectedAccessBySearch = connect(mapStateToProps)(AccessBySearch);

export default checkAccessRights(ConnectedAccessBySearch, transactions.FIND_PATIENTS);
