import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { dmpStatuses } from '../../dmpconnect/constants';
import ALDButton from '../TLSi/ALD/ALDButton';
import DmpAccessButton from './DmpAccessButton';
import TitleTooltip from '../Common/TitleTooltip';

const DMPStatusActions = ({
  ins, status, authorization, accessDMP, aldActive,
  closingDate, closingMessage, certifiedInsError,
  isTreatingPhysician,
  noButton,
  tlsSubSection,
}) => (
  <div className="dmp-status-actions">
    <>
      {certifiedInsError ? (
        <span>
          <i className="la la-question-circle mr-1" />
          <span>Impossible de récupérer l&apos;identité certifiée</span>
        </span>
      ) : (
        <>
          <DmpAccessButton
            ins={ins}
            authorization={authorization}
            status={status}
            accessDMP={accessDMP}
            isTreatingPhysician={isTreatingPhysician}
          />
          {status === dmpStatuses.DMPIsClosed && (
            <>
              <i className="ic-negative mr-2" />
              <div>
                <div>
                  DMP fermé depuis le
                  {' '}
                  {moment.utc(closingDate).format('DD/MM/YYYY')}
                  .
                  {' '}
                </div>
                <TitleTooltip
                  id={`dmp-closed-reason-${ins}`}
                  text={closingMessage.length > 50 ? closingMessage : undefined}
                >
                  <div>
                    Raison :
                    {' '}
                    {closingMessage.length > 100 ? `${closingMessage.substring(0, 50)}...` : closingMessage}
                  </div>
                </TitleTooltip>
              </div>
            </>

          )}
          {status === dmpStatuses.DMPNotFound && (
            <span>
              <i className="ic-question-mark mr-2" />
              <span>DMP inexistant</span>
            </span>
          )}
        </>
      )}
    </>
    {aldActive && !noButton && tlsSubSection && <ALDButton subSection={tlsSubSection} />}
  </div>
);

DMPStatusActions.propTypes = {
  status: PropTypes.number,
  authorization: PropTypes.number,
  closingDate: PropTypes.string,
  closingMessage: PropTypes.string,
  isTreatingPhysician: PropTypes.bool,
  ins: PropTypes.string,
  accessDMP: PropTypes.func,
  aldActive: PropTypes.bool,
  certifiedInsError: PropTypes.bool,
  noButton: PropTypes.bool,
  tlsSubSection: PropTypes.string,
};

DMPStatusActions.defaultProps = {
  status: null,
  authorization: null,
  closingDate: null,
  closingMessage: null,
  ins: null,
  accessDMP: null,
  aldActive: false,
  certifiedInsError: false,
  isTreatingPhysician: false,
  noButton: false,
  tlsSubSection: null,
};

export default DMPStatusActions;
